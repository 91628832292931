import { ActivityIndicator, Alert, Switch, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import React, { useContext, useState } from 'react'
import useMentorProfile from '../../hooks/useMentorProfile'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import ActionButton from '../ActionButton'
import Loading from '../../screens/Loading'

const ToggleAvailability = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { mentorProfile, setAvailability } = useMentorProfile()
  const { me, refetchMe } = useContext<AuthContextValue>(AuthContext)
  const handleSetAvailability = async (value: boolean) => {
    try {
      setIsLoading(true)
      await setAvailability(value)
    } catch (error) {
      Alert.alert('Kunne ikke gjøre deg tilgjengelig. Prøv igjen senere')
    } finally {
      setIsLoading(false)
    }
  }

  const handleRefetch = async () => {
    setIsLoading(true)
    await refetchMe()
    setIsLoading(false)
  }

  const isAvailable = mentorProfile?.isAvailable

  if (me?.mentorProfile?.isApproved === false)
    return <NotApproved onPress={handleRefetch} isLoading={isLoading} />
  return (
    <View style={tw`bg-white relative border-b border-grey-light`}>
      <View style={tw`flex-row items-center justify-center mx-3 pb-5 pt-2`}>
        {isLoading ? (
          <ActivityIndicator color="#0679DD" style={tw`mx-5`} />
        ) : (
          <Text style={tw.style(`mr-5`, { [`text-grey`]: isLoading })}>
            {' '}
            {isAvailable ? 'Tilgjengelig' : 'Ikke tilgjengelig'}
          </Text>
        )}
        <Switch
          trackColor={{ false: '#767577', true: '#0679DD' }}
          thumbColor="#f4f3f4"
          ios_backgroundColor="#3e3e3e"
          onValueChange={handleSetAvailability}
          value={isAvailable}
        />
      </View>
    </View>
  )
}

const NotApproved = ({ onPress, isLoading }: { onPress: () => void; isLoading: boolean }) => {
  return (
    <View style={tw`bg-white relative border-b border-grey-light`}>
      <View style={tw`items-center bg-blue justify-center mx-3 p-6 rounded-2xl`}>
        <Text style={tw`text-white text-lg font-bold`}>Venter på godkjenning</Text>
        <Text style={tw`mt-2 text-white text-sm`}>
          Du må vente på at en administrator godkjenner deg som mentor.
        </Text>
        <Text style={tw`mt-2 text-white text-sm`}>
          Se over at du har fylt ut nødvendig informasjon om deg selv i personlig informasjon og at
          du har satt opp din stripe-konto under utbetaling
        </Text>
        <ActionButton
          label={isLoading ? 'Sjekker status' : 'Sjekk status'}
          onPress={onPress}
          color={'blue'}
          isLoading={isLoading}
        />
      </View>
    </View>
  )
}

export default ToggleAvailability
