import { Text, View } from 'react-native'
import React from 'react'
import tw from '../../../lib/tailwind'
import { MentorProfile } from '../../generated/graphql'

interface Props {
  mentor: MentorProfile
}

const MentorCardAbout = ({ mentor }: Props) => {
  return (
    <View>
      <Text style={tw`text-grey-dark mb-3 text-base leading-snug`}>{mentor.bio}</Text>
      <View style={tw`flex-row flex-wrap`}>
        {mentor.topics?.map((topic) => (
          <View style={tw`px-2 py-1 bg-grey-light rounded-lg my-1 mr-2`} key={topic.id}>
            <Text style={tw`text-grey-dark text-sm text-center font-medium`}>{topic.name}</Text>
          </View>
        ))}
      </View>
    </View>
  )
}

export default MentorCardAbout
