import * as React from 'react'
import Svg, { G, Defs, Path, ClipPath, Rect } from 'react-native-svg'

const UserIcon = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      id="Group_5351"
      data-name="Group 5351"
      width="28"
      height="28"
      viewBox="0 0 28 28"
    >
      <Defs>
        <ClipPath id="clippath">
          <Rect
            id="Rectangle_1379"
            data-name="Rectangle 1379"
            width="28"
            height="28"
            fill="#313131"
          />
        </ClipPath>
      </Defs>
      <G id="Group_5350" dataname="Group 5350" clipPath="url(#clippath)">
        <Path
          id="Path_4423"
          data-name="Path 4423"
          d="M14,28A14,14,0,1,1,28,14,14,14,0,0,1,14,28M14,2A11.992,11.992,0,0,0,4.866,21.773,7,7,0,0,1,11.5,17h5a7,7,0,0,1,6.633,4.773A11.995,11.995,0,0,0,14,2m0,13.007A5.266,5.266,0,0,1,9.009,9.5,5.268,5.268,0,0,1,14,4a5.266,5.266,0,0,1,4.991,5.5A5.266,5.266,0,0,1,14,15.005"
          fill="#313131"
          fillRule="evenodd"
        />
      </G>
    </Svg>
  )
}
export default UserIcon
