import React, { useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Alert,
  LayoutAnimation,
  Platform,
  SafeAreaView,
  StatusBar,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import tw from '../../../lib/tailwind'
import { useTimer } from '../../hooks/useTimer'
import { zeroPad } from 'react-countdown'
import WherebyMeeting from '../../components/WherebyMeeting'
import useMeeting from '../../hooks/useMeeting'
import useMe from '../../hooks/useMe'
import { RoleValue } from '../../generated/graphql'
import Refill from '../../components/Refill'
import useRemainingTime from '../../hooks/useRemainingTime'
import { Duration, intervalToDuration } from 'date-fns'

const MentorRoomScreen = ({ navigation }: any) => {
  const [open, setOpen] = useState(false)

  const [durationLeft, setDurationLeft] = useState<Duration>()
  const { data, endMeeting } = useMeeting()
  const { me } = useMe()
  const { totalMillis: totalRemainingMillis } = useRemainingTime()
  const {
    minutes: minutesElapsed,
    seconds: secondsElapsed,
    totalMillis: totalMillisElapsed,
  } = useTimer({
    startTime: data?.meeting?.createdAt ? new Date(data?.meeting?.createdAt) : new Date(),
  })
  const isStudent = me?.role && me.role === RoleValue.Student

  const toggleOpen = () => {
    setOpen(!open)
    LayoutAnimation.configureNext(LayoutAnimation.create(150, 'easeInEaseOut', 'opacity'))
  }
  useEffect(() => {
    if (!isStudent) {
      // Mentors dont care about time 😎 ⏰
      return
    }

    const difference = totalRemainingMillis - totalMillisElapsed

    if (difference < 0) {
      // User is out of minutes, stop the meeting
      endMeeting({})
      return
    }

    const durationUntilRemainingTimeEnds = intervalToDuration({
      start: new Date(),
      end: new Date(Date.now() + (totalRemainingMillis - totalMillisElapsed)),
    })

    setDurationLeft(durationUntilRemainingTimeEnds)
    if (
      durationLeft &&
      durationLeft.hours === 0 &&
      durationLeft.minutes &&
      durationLeft.minutes < 15
    ) {
      setOpen(true)
    }
  }, [secondsElapsed])

  const handleEndMeeting = async () => {
    if (Platform.OS === 'web') {
      await endMeeting({})
    } else {
      Alert.alert(
        'Avslutt møte',
        'Er du sikker på at du vil avslutte møtet?',
        [
          {
            text: 'Avbryt',
            style: 'cancel',
          },
          {
            text: 'Avslutt',
            style: 'destructive',
            onPress: () => endMeeting({}),
          },
        ],
        {
          cancelable: true,
        }
      )
    }
  }

  const roomUrl = data?.meeting?.hostRoomUrl
  return (
    <SafeAreaView style={tw.style(`flex-1 bg-white`)}>
      {roomUrl ? (
        <WherebyMeeting roomUrl={roomUrl} />
      ) : (
        <View style={tw`flex-1 flex-row items-center justify-center bg-white p-10`}>
          <Text>Henter møterom</Text>
          <ActivityIndicator color="blue" style={tw`ml-3`} />
        </View>
      )}
      <View style={tw`px-10 py-5 flex-row justify-between items-center`}>
        {isStudent ? (
          <TouchableOpacity onPress={toggleOpen} style={tw`items-center mx-2 text-sm`}>
            <Text>Gjenstående minutter</Text>
            {durationLeft && (
              <Text>
                {zeroPad(String(durationLeft.hours))}:{zeroPad(String(durationLeft.minutes))}:
                {zeroPad(String(durationLeft.seconds))}
              </Text>
            )}
          </TouchableOpacity>
        ) : (
          <Text style={tw`font-bold text-center`}>
            {zeroPad(String(minutesElapsed))}:{zeroPad(String(secondsElapsed))}
          </Text>
        )}
        <TouchableOpacity
          style={tw`bg-blue text-center px-4 py-3 rounded-2xl`}
          onPress={() => handleEndMeeting()}
        >
          <Text style={tw`text-white font-bold text-center`}>Avslutt møtet</Text>
        </TouchableOpacity>
      </View>
      <>{open && isStudent && <Refill toggleOpen={toggleOpen} />}</>
    </SafeAreaView>
  )
}

export default MentorRoomScreen
