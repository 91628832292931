import React from 'react'
import { LogBox, StatusBar } from 'react-native'

import { AuthProvider } from './src/contexts/auth'
import Navigator from './src/navigators'

import * as Sentry from 'sentry-expo'
import { NotificationProvider } from './src/contexts/push-notifications'

Sentry.init({
  dsn: 'https://85a204eb62404779a4eff275702747b9@o307156.ingest.sentry.io/6493429',
  // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
  // We recommend adjusting this value in production.
  enableInExpoDevelopment: false,
  tracesSampleRate: 1.0,
  enableNative: false,
})

// Remove the annoying EventEmitter.removeListener has been deprecated warning.
// It is being logged because dependency uses old react-native api
// DOES NOT WORK for some reason
LogBox.ignoreLogs(['EventEmitter.removeListener'])
LogBox.ignoreLogs(['EventEmitter'])

const App = () => {
  StatusBar.setBarStyle('dark-content', true)
  return (
    <AuthProvider>
      <NotificationProvider>
        <Navigator />
      </NotificationProvider>
    </AuthProvider>
  )
}

export default App
