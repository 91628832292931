import React, { useState } from 'react'
import MentorCard from '../../components/MentorCard'
import {
  ActivityIndicator,
  Alert,
  FlatList,
  LayoutAnimation,
  SafeAreaView,
  StatusBar,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from 'react-native'
import tw from '../../../lib/tailwind'
import Topics from '../../components/Topics'
import { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import useMeeting from '../../hooks/useMeeting'
import ActionButton from '../../components/ActionButton'

const HomeScreen = ({ navigation }: any) => {
  const [topicIds, setTopicIds] = useState<string[]>([])
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const { data, mutate: mutateMentors } = getSdk().useMentors('mentors', { topicIds }, {})

  const { sendMentorReservation } = useMeeting()
  const [selected, setSelected] = useState<string>()
  const [refreshing, setRefreshing] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)

  const [sendReservationIsLoading, setSendReservationIsLoading] = useState(false)
  const handleTopics = async (selectedTopics: string[]) => {
    await setTopicIds(selectedTopics)
    setFilterOpen(false)
    await mutateMentors()
  }
  const toggleOpen = () => {
    setFilterOpen(!filterOpen)
    LayoutAnimation.configureNext(LayoutAnimation.create(150, 'easeInEaseOut', 'opacity'))
  }

  const handleRefresh = () => {
    setRefreshing(true)
    mutateMentors().then(() => setRefreshing(false))
  }

  const handleBooking = async (mentorId: string) => {
    try {
      setSendReservationIsLoading(true)
      await sendMentorReservation({ mentorId })
    } catch (error) {
      await mutateMentors()
      console.log(error)
      setSendReservationIsLoading(false)
      Alert.alert(
        'Kunne ikke booke mentor. Det kan hende at den ikke lenger er ledig. Prøv igjen senere.'
      )
    }
  }

  return (
    <SafeAreaView
      style={tw.style(`flex-1 bg-white`, {
        paddingTop: StatusBar.currentHeight,
      })}
    >
      <View style={tw`bg-white flex-1 relative`}>
        <View style={tw`flex-row items-center justify-center mx-3 py-5`}>
          <TouchableOpacity
            style={tw`px-9 py-2 bg-white shadow-md rounded-lg`}
            onPress={toggleOpen}
          >
            <Text style={tw`font-bold`}>Velg fag</Text>
          </TouchableOpacity>
        </View>
        {filterOpen && (
          <Topics toggle={setFilterOpen} handleTopics={handleTopics} topicIds={topicIds} />
        )}
        {!data ? (
          <View style={tw`flex-1 justify-center items-center`}>
            <Text>Henter mentorliste</Text>
            <ActivityIndicator style={tw`mt-4`} size="large" />
          </View>
        ) : data && !data.mentors?.length ? (
          <View style={tw`flex-1 justify-center items-center`}>
            <Text>Ingen mentorer er tilgjengelige for øyeblikket</Text>
          </View>
        ) : (
          <FlatList
            data={data?.mentors?.sort((a, b) =>
              a.isFavorited === b.isFavorited ? 0 : a.isFavorited ? -1 : 1
            )}
            onRefresh={handleRefresh}
            refreshing={refreshing}
            style={tw.style(selected && 'mb-28')}
            renderItem={({ item }: any) => (
              <View style={{ marginVertical: 6.5, paddingHorizontal: 10 }}>
                <MentorCard
                  active={selected === item.id}
                  data={item}
                  key={item.id}
                  onSelect={(id: string) => setSelected(id)}
                  refresh={mutateMentors}
                />
              </View>
            )}
          />
        )}
      </View>
      {selected && (
        <View
          style={tw`absolute z-10 flex-1 w-full px-3 py-4 rounded-t-2xl bottom-0 shadow-lg left-0 bg-white `}
        >
          <ActionButton
            label={'Bestill mentor'}
            onPress={() => handleBooking(selected)}
            disabled={sendReservationIsLoading}
            isLoading={sendReservationIsLoading}
          />
        </View>
      )}
    </SafeAreaView>
  )
}

export default HomeScreen
