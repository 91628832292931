import { useSWRConfig } from 'swr'
import { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../contexts/auth'
import { Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const useLogout = () => {
  const { cache } = useSWRConfig()
  const { setLogout } = useContext<AuthContextValue>(AuthContext)

  const logout = () => {
    cache.delete('me')
    AsyncStorage.removeItem('jwt')
    setLogout()
  }

  return { logout }
}
