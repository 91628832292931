export const SUPPORT_URL = 'https://www.minmentor.no/kontakt'
export const SALES_TERMS_URL = 'https://www.minmentor.no/salgsbetingelser'
export const TERMS_OF_SERVICE = 'https://www.minmentor.no/vilkaar'
export const VIPPS_REDIRECT_URL_WEB =
  process.env.NODE_ENV === 'production'
    ? 'https://app.minmentor.no/callback'
    : 'https://app-staging.minmentor.no/callback'
export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://backend.minmentor.no'
    : 'https://backend-staging.minmentor.no'
