import { Text } from 'react-native'
import tw from '../../../lib/tailwind'
import React from 'react'
import { format } from 'date-fns'

const Duration = ({ meeting }: any) => {
  const dateEnded = new Date(meeting?.endedAt).getTime()
  const dateStarted = new Date(meeting?.createdAt).getTime()
  const duration = dateEnded - dateStarted

  const formattedDuration = format(new Date(duration), 'mm:ss')
  return <Text style={tw`text-base font-bold`}>{formattedDuration}</Text>
}

export default Duration
