import { Platform, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import React, { useContext, useEffect } from 'react'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import Loading from '../../screens/Loading'
import StripeOnboarding from './onboarding'
import StripeNoAccount from './noAccount'
import StripePendingApproval from './pendingApproval'
import StripeComplete from './complete'

const StripeAccount = () => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const {
    data: stripeData,
    isValidating,
    mutate: getStripeStatus,
  } = getSdk().useStripeStatus('stripe-status', { isWeb: Platform.OS === 'web' }, {})

  useEffect(() => {
    void getStripeStatus()
  }, [])

  if (isValidating)
    return (
      <View>
        <Loading />
        <Text style={tw`text-center`}>Henter Stripe-status</Text>
      </View>
    )
  else if (stripeData?.stripeStatus.status === 'NOT_CONNECTED')
    return <StripeNoAccount refetch={getStripeStatus} />
  else if (stripeData?.stripeStatus.status === 'ONBOARDING')
    return <StripeOnboarding data={stripeData?.stripeStatus} />
  else if (stripeData?.stripeStatus.status === 'PENDING_APPROVAL')
    return <StripePendingApproval data={stripeData?.stripeStatus} />
  else if (stripeData?.stripeStatus.status === 'COMPLETE')
    return <StripeComplete data={stripeData?.stripeStatus} />
  else return <Text>Noe gikk galt. Vennligst prøv igjen senere.</Text>
}

export default StripeAccount
