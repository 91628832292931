import { FontAwesome } from '@expo/vector-icons'
import React from 'react'
import { Text, View } from 'react-native'
import { Rating } from '../MentorCard'

const StarRating = ({
  rating,
  reviewCount,
  ...props
}: {
  rating: number | null | undefined
  reviewCount?: number | null | undefined
}) => {
  const numbers = [...new Array(5).keys()].map((n) => n + 1)

  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
      {...props}
    >
      {numbers.map((n) => (
        <FontAwesome
          key={n}
          name={n <= rating ? 'star' : 'star-o'}
          size={16}
          color={n <= rating ? '#0679DD' : '#8B8B8B'}
          style={{ marginLeft: n === 1 ? 0 : 5 }}
        />
      ))}
      <Text style={{ color: '#8B8B8B', marginLeft: 7, fontSize: 12 }}>
        {(reviewCount || reviewCount === 0) && `(${reviewCount?.toString()})`}
      </Text>
    </View>
  )
}

export default StarRating
