import { StripeStatusResponse } from '../../generated/graphql'
import { Linking, Pressable, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import { FontAwesome } from '@expo/vector-icons'
import React from 'react'

const StripeComplete = ({ data }: { data?: StripeStatusResponse }) => {
  return (
    <View style={tw`m-3 mb-1`}>
      <View style={tw` m-3 mb-1`}>
        <Text style={tw`text-center`}>
          <FontAwesome name={'check'} size={50} color={'#0679DD'} />
        </Text>
        <Text style={tw`m-3  mb-8 text-center`}>
          Din stripe-konto er godkjent og klar for utbetalinger.
        </Text>
        <Pressable
          style={tw`bg-[#0679DD] text-center p-4 rounded-2xl`}
          onPress={() => data?.dashboardUrl && Linking.openURL(data.dashboardUrl)}
        >
          <Text style={tw`text-lg text-white font-bold text-center`}>Se dine utbetalinger</Text>
        </Pressable>
      </View>
    </View>
  )
}

export default StripeComplete
