import { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../contexts/auth'
import { MutationUpdateMentorProfileArgs, MyMentorQuery } from '../generated/graphql'
import useMe from './useMe'
import { useSWRConfig } from 'swr'

const useMentorProfile = () => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const { data, mutate } = getSdk().useMyMentor('mentorProfile')

  const setAvailability = async (value: boolean): Promise<void> => {
    await mutate(
      async () => {
        const { setMentorAvailability } = await getSdk().setMentorAvailability({
          availability: value,
        })
        return {
          myMentor: setMentorAvailability,
        }
      },
      {
        optimisticData(currentData?) {
          return {
            ...currentData,
            ...(currentData && {
              myMentor: {
                ...currentData.myMentor,
                isAvailable: value,
              },
            }),
          } as MyMentorQuery
        },
        revalidate: true,
        rollbackOnError: true,
      }
    )
  }

  const updateMentorProfile = (payload: MutationUpdateMentorProfileArgs) => {
    return mutate(
      async (mentorQuery) => {
        const { updateMentorProfile } = await getSdk().updateMentorProfile(payload)

        return {
          ...mentorQuery,
          myMentor: updateMentorProfile,
        }
      },
      {
        optimisticData(currentData?) {
          return {
            ...currentData,
            myMentor: {
              ...currentData?.myMentor,
              ...payload,
            },
          } as MyMentorQuery
        },
        revalidate: true,
        rollbackOnError: true,
      }
    )
  }
  return {
    mentorProfile: data?.myMentor,
    setAvailability,
    updateMentorProfile,
  }
}
export default useMentorProfile
