import React, { useState } from 'react'
import { SafeAreaView, Text, TextInput, TouchableOpacity, View } from 'react-native'
import tw from '../../../lib/tailwind'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import StarRating from 'react-native-star-rating-widget'
// @ts-ignore
import { format } from 'date-fns'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import Duration from '../../components/Duration'
import useMe from '../../hooks/useMe'
import useMeeting from '../../hooks/useMeeting'
import ActionButton from '../../components/ActionButton'

type Rating = 1 | 2 | 3 | 4 | 5

const AddReview = ({ navigation }: any) => {
  const { data, rateMeeting, skipRating } = useMeeting()
  const { me } = useMe()
  const [message, setMessage] = useState('')
  const [rating, setRating] = useState<Rating>()
  const isStudent = me?.role == 'STUDENT'
  const [isLoading, setIsLoading] = useState(false)

  const handleSendRating = async () => {
    setIsLoading(true)
    if (!data?.meeting?.id) {
      return
    }
    if (!rating) {
      await skipRating({ meetingId: data.meeting.id })
    } else {
      // We create a rating
      await rateMeeting({ meetingId: data.meeting.id, message, rating })
    }
    setIsLoading(false)
  }
  return (
    <SafeAreaView style={tw`bg-white flex-1 pt-10`}>
      <KeyboardAwareScrollView style={tw`flex-1`}>
        <View style={tw`items-center p-3 flex-1`}>
          <Text style={tw`text-3xl font-bold text-blue my-3`}>Time avsluttet</Text>
          {data?.meeting?.endedAt ? (
            <Text style={tw`text-base font-bold`}>
              Varighet:&nbsp;
              <Duration meeting={data.meeting} />
            </Text>
          ) : null}
          <Text style={tw`text-xl font-bold`}>Takk for timen!</Text>
          <View style={tw`items-center justify-center my-4`}>
            <StarRating
              rating={rating as number}
              onChange={setRating as () => number}
              enableHalfStar={false}
            />
          </View>
          <Text>
            {isStudent
              ? 'Vil du gi en vurdering av timen? Denne vil bli synlig på mentorens profil.'
              : 'Gi gjerne en vurdering av timen. Vurderingen vil ikke bli synlig for andre brukere.'}
          </Text>
          <TextInput
            style={tw`bg-grey-light w-full rounded-xl p-4 text-base my-5`}
            multiline
            textAlignVertical={'top'}
            numberOfLines={5}
            value={message}
            onChangeText={setMessage}
          />

          <ActionButton
            label={
              message || rating ? (isLoading ? 'Sender' : 'Send') : 'Avslutt uten å gi vurdering'
            }
            onPress={handleSendRating}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )
}

export default AddReview
