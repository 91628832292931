import React, { useContext } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { NavigationContainer } from '@react-navigation/native'

import StudentNavigator from './Student'
import MentorNavigator from './Mentor'
import PaymentScreen from '../../screens/Payment'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import { Platform } from 'react-native'

const LoggedInNavigator = () => {
  const { me } = useContext<AuthContextValue>(AuthContext)
  const LoggedIn = createNativeStackNavigator()

  return (
    <LoggedIn.Navigator>
      {me?.role === 'STUDENT' ? (
        <>
          <LoggedIn.Screen
            name="StudentNavigator"
            component={StudentNavigator}
            options={{ headerShown: false }}
          />
          {Platform.OS === 'web' && <LoggedIn.Screen name="Payment" component={PaymentScreen} />}
        </>
      ) : (
        me?.role === 'MENTOR' && (
          <LoggedIn.Screen
            name="MentorNavigator"
            component={MentorNavigator}
            options={{ headerShown: false }}
          />
        )
      )}
    </LoggedIn.Navigator>
  )
}

export default LoggedInNavigator
