import React from 'react'
import tw from '../../../lib/tailwind'
import { SafeAreaView, StatusBar, Text, View } from 'react-native'
import CountdownTimer from '../../components/CountdownTimer'
import useMeeting from '../../hooks/useMeeting'

const RequestScreen = () => {
  const { data } = useMeeting()
  const parsedDate = new Date(data?.request?.requestSentAt)

  return (
    <SafeAreaView style={tw.style(`flex-1 bg-white`, { paddingTop: StatusBar.currentHeight })}>
      <View style={tw`items-center p-3`}>
        <Text style={tw`text-3xl font-bold text-blue my-3`}>Forespørsel sendt!</Text>
        <Text style={tw`text-xl mt-5 text-center`}>
          Venter på at {data?.request?.mentor?.firstName} skal bekrefte din forespørsel.
        </Text>
        <Text style={tw`text-xl mt-1 text-center`}>Vi vil gi beskjed når timen begynner.</Text>
        <Text style={tw`text-2xl font-bold mt-5`}>Timen starter innen:</Text>
        <Text style={tw`text-3xl font-bold text-blue my-3`}>
          {Boolean(data?.request?.requestSentAt) && (
            <CountdownTimer parsedDate={parsedDate.getTime()} minutes={15} />
          )}
        </Text>
        <Text style={tw`text-base text-grey text-center`}></Text>
      </View>
    </SafeAreaView>
  )
}

export default RequestScreen
