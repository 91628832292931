import React, { useContext, useState } from 'react'
import {
  Image,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  Animated,
  View,
  LayoutAnimation,
  ActivityIndicator,
} from 'react-native'
import tw from '../../../lib/tailwind.js'

import StarRating from '../StarRating'

import { FontAwesome } from '@expo/vector-icons'
import MentorCardAbout from './about'
import { MentorReviews } from '../Reviews'
import { MentorProfile } from '../../generated/graphql'
import AuthContext, { AuthContextValue } from '../../contexts/auth'

export type Rating = 1 | 2 | 3 | 4 | 5

const MentorCard = ({
  active,
  data,
  onSelect,
  refresh,
}: {
  onSelect: any
  active?: boolean
  data: MentorProfile
  refresh: any
}) => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)

  const [toggleLoading, setToggleLoading] = useState(false)
  const toggleOpen = () => {
    onSelect(active ? undefined : data.id)
    LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut)
  }
  const [tab, setTab] = useState('about')
  const reviewCount = data?.ratingsCount || 0
  const rating = data?.averageRating || 0

  const handleToggleFavorite = async () => {
    setToggleLoading(true)
    await getSdk().toggleFavoriteMentor({ mentorId: data.id })
    await refresh()
    setToggleLoading(false)
  }

  if (!data?.user) return null
  return (
    <View>
      <TouchableWithoutFeedback onPress={() => toggleOpen()}>
        <View
          style={tw.style(
            'flex-row px-3 py-2 bg-white rounded-2xl shadow-lg border-2 border-white',
            active && 'bg-[#F0F8FF] border-[#6EBBFF]'
          )}
        >
          <View>
            <Image
              source={{
                uri: data?.avatarUrl + '?id=' + data.id,
              }}
              style={tw`w-20 h-20 rounded-full`}
            />
            {data.isAvailable && (
              <View
                style={tw`absolute w-4 h-4 rounded-full bg-[#4EFF00] top-1 right-1 border-2 border-white`}
              ></View>
            )}
          </View>
          <View style={tw`ml-4 flex-1 justify-center`}>
            <Text style={tw`text-lg font-bold mb-1`}>{data?.user?.firstName}</Text>
            <Text style={tw`text-grey mb-2`}>{data.title}</Text>
            <StarRating rating={rating} reviewCount={reviewCount} />
          </View>
        </View>
      </TouchableWithoutFeedback>
      {active && (
        <Animated.View style={tw.style('px-3 overflow-hidden', active ? 'h-auto' : 'h-0')}>
          <View style={tw`flex-row justify-between my-3`}>
            <View>
              <TouchableOpacity
                style={tw`border rounded-lg border-grey-dark flex-row px-3 py-2 ${
                  tab === 'about' ? 'border-2 border-blue' : ''
                }`}
                onPress={() => setTab('about')}
              >
                <Text style={tw`text-center text-grey-dark`}>Om meg</Text>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity
                style={tw`border rounded-lg border-grey-dark flex-row px-3 py-2 ${
                  tab === 'reviews' ? 'border-2 border-blue' : ''
                }`}
                onPress={() => setTab('reviews')}
              >
                <Text style={tw`text-center text-grey-dark`}>
                  {data?.ratingsCount?.toString()} Anmeldelser
                </Text>
              </TouchableOpacity>
            </View>
            <View>
              <TouchableOpacity
                style={tw`border rounded-lg border-grey-dark flex-row px-3 py-2 ${
                  data.isFavorited ? 'bg-blue text-white' : 'bg-white'
                }`}
                onPress={handleToggleFavorite}
              >
                <Text>
                  {toggleLoading ? (
                    <ActivityIndicator
                      size={'small'}
                      color={data.isFavorited ? '#fff' : '#0679DD'}
                    />
                  ) : (
                    <FontAwesome
                      name="bookmark-o"
                      size={17}
                      color={data.isFavorited ? '#fff' : '#696969'}
                    />
                  )}
                </Text>
                <Text style={tw`ml-2 ${data.isFavorited ? 'text-white' : 'text-black'}`}>
                  {data.isFavorited ? 'Fjern favoritt' : 'Favoritt'}
                </Text>
              </TouchableOpacity>
            </View>
          </View>
          {tab === 'about' ? (
            <MentorCardAbout mentor={data} />
          ) : tab === 'reviews' ? (
            <MentorReviews userId={data.user?.id} />
          ) : (
            ''
          )}
        </Animated.View>
      )}
    </View>
  )
}

export default MentorCard
