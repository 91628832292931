import { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../contexts/auth'
import {
  AcceptRequestMutationVariables,
  CancelReservationMutationVariables,
  CreateRatingMutationVariables,
  CreateReservationMutationVariables,
  DeclineRequestMutationVariables,
  EndMeetingMutationVariables,
  SendRequestMutationVariables,
  SkipRatingMutationVariables,
} from '../generated/graphql'

const useMeeting = () => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const { data, mutate } = getSdk().useMeeting(
    'meeting',
    {},
    {
      refreshInterval: 3000,
    }
  )

  const sendMentorReservation = (payload: CreateReservationMutationVariables) => {
    return mutate(
      async () => {
        const { createReservation } = await getSdk().createReservation(payload)
        return {
          ...data,
          reservation: createReservation,
        }
      },
      {
        revalidate: true,
        rollbackOnError: true,
      }
    )
  }

  const cancelMentorReservation = (variables: CancelReservationMutationVariables) => {
    return mutate(
      async () => {
        await getSdk().cancelReservation(variables)
        return {
          ...data,
          reservation: null,
        }
      },
      {
        optimisticData(currentData?) {
          return {
            ...currentData,
            reservation: null,
          }
        },
        revalidate: true,
      }
    )
  }

  const sendRequest = (variables: SendRequestMutationVariables) => {
    return mutate(
      async () => {
        const { sendRequest } = await getSdk().sendRequest(variables)
        return {
          ...data,
          request: sendRequest,
        }
      },
      {
        revalidate: true,
      }
    )
  }

  const acceptMeetingRequest = (variables: AcceptRequestMutationVariables) => {
    return mutate(
      async () => {
        const { acceptRequest } = await getSdk().acceptRequest(variables)
        return {
          ...data,
          reservation: acceptRequest,
        }
      },
      {
        revalidate: true,
      }
    )
  }

  const endMeeting = (variables: EndMeetingMutationVariables) => {
    if (!data?.meeting) {
      return
    }

    return mutate(
      async () => {
        const { endMeeting } = await getSdk().endMeeting(variables)
        return {
          ...data,
          meeting: endMeeting,
        }
      },
      {
        optimisticData: {
          ...data,
          meeting: {
            ...data.meeting,
            endedAt: new Date(),
          },
        },
        revalidate: true,
      }
    )
  }

  const declineRequest = (variables: DeclineRequestMutationVariables) => {
    return mutate(
      async () => {
        const { declineRequest } = await getSdk().declineRequest(variables)
        return {
          ...data,
          reservation: declineRequest,
          request: null,
        }
      },
      {
        optimisticData: {
          ...data,
          request: null,
        },
        revalidate: true,
      }
    )
  }

  const rateMeeting = (variables: CreateRatingMutationVariables) => {
    const optimisticData = {
      ...data,
      meeting: null,
    }

    return mutate(
      async () => {
        const { createRating } = getSdk()
        await createRating(variables)
        return optimisticData
      },
      {
        revalidate: true,
        optimisticData,
      }
    )
  }

  const skipRating = (variables: SkipRatingMutationVariables) => {
    const optimisticData = {
      ...data,
      meeting: null,
    }
    return mutate(
      async () => {
        const { skipRating } = getSdk()
        await skipRating(variables)
        return optimisticData
      },
      {
        revalidate: true,
        optimisticData,
      }
    )
  }

  return {
    data,
    mutate,
    sendMentorReservation,
    cancelMentorReservation,
    sendRequest,
    acceptMeetingRequest,
    endMeeting,
    declineRequest,
    rateMeeting,
    skipRating,
  }
}

export default useMeeting
