import { Text } from 'react-native'
import React from 'react'
import Countdown, { zeroPad } from 'react-countdown'

const CountdownTimer = ({
  parsedDate,
  minutes,
  onComplete,
}: {
  parsedDate: number
  minutes: number
  onComplete?: () => void
}) => {
  const Completionist = () => <Text>0:00</Text>

  const renderer = ({
    minutes,
    seconds,
    completed,
  }: {
    minutes: number
    seconds: number
    completed: boolean
  }) => {
    if (completed) {
      return <Completionist />
    } else {
      return (
        <Text>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </Text>
      )
    }
  }
  return (
    <Countdown
      date={parsedDate + minutes * 60 * 1000}
      renderer={renderer}
      onComplete={onComplete ? onComplete : () => {}}
    >
      <Completionist />
    </Countdown>
  )
}

export default CountdownTimer
