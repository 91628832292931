import { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../contexts/auth'
import { SelectedTopicsQuery, SelectTopicsMutationVariables } from '../generated/graphql'
import { cache } from 'swr/dist/utils/config'
import { useSWRConfig } from 'swr'

const useTopics = () => {
  const { cache } = useSWRConfig()

  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const {
    data: topics,
    isValidating,
    mutate
  } = getSdk().useTopics(
    'topics',
    {},
    {
      revalidateOnMount: !cache.get('topics')
    }
  )
  const { data: selectedTopics, mutate: mutateSelectedTopics } =
    getSdk().useSelectedTopics('selectedTopics')

  const selectTopics = async (payload: SelectTopicsMutationVariables): Promise<void> => {
    await mutateSelectedTopics(
      async () => {
        const { selectTopics } = await getSdk().selectTopics(payload)
        return {
          selectedTopics: selectTopics,
        }
      },
      {
        revalidate: true,
        rollbackOnError: true,
      }
    )
  }
  return {
    topics,
    selectedTopics,
    mutate,
    isValidating,
    selectTopics,
  }
}

export default useTopics
