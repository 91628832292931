import { ActivityIndicator, Image, ImageBackground, Pressable, Text, View } from 'react-native'
import { BlurView } from 'expo-blur'
import React, { useState } from 'react'
import tw from '../../../lib/tailwind.js'
import { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import * as Linking from 'expo-linking'
import useVippsLogin from '../../hooks/useVippsLogin'
import ActionButton from '../../components/ActionButton'
import Svg, { Path } from 'react-native-svg'
import { TERMS_OF_SERVICE } from '../../utils/constants'

const LoginScreen = () => {
  const { login, isLoading } = useVippsLogin()
  const { setToken, authError } = useContext<AuthContextValue>(AuthContext)

  const handleLogin = async () => {
    const token = await login()
    setToken(token)
  }

  return (
    <View style={tw`flex-1 p-0 justify-center items-center`}>
      <ImageBackground
        source={require('../../assets/login.png')}
        style={tw`w-full h-full justify-between max-w-[400px]`}
        blurRadius={0}
      >
        <View style={tw`items-center px-10 h-40 pt-20`}></View>
        <View style={tw`px-5 py-10 flex items-center justify-center`}>
          <View style={tw`rounded-[40px] overflow-hidden`}>
            <BlurView style={tw`max-w-[355px] justify-center p-8`} intensity={100}>
              {authError && <Text style={tw`text-lg text-center mb-4 font-bold`}>{authError}</Text>}
              <View style={tw`mb-6`}>
                <ActionButton
                  label={isLoading ? 'Logger inn' : 'Logg inn med Vipps'}
                  color={'orange'}
                  isLoading={isLoading}
                  onPress={handleLogin}
                />
              </View>
              <View style={tw`items-center`}>
                <Text
                  style={tw`text-center text-white text-xs`}
                  onPress={() => Linking.openURL(TERMS_OF_SERVICE)}
                >
                  Ved å opprette en bruker godtar du våre brukervilkår
                </Text>
              </View>
            </BlurView>
          </View>
        </View>
      </ImageBackground>
    </View>
  )
}

export default LoginScreen
