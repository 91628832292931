import React, { useState } from 'react'
import {
  ActivityIndicator,
  Alert,
  Image,
  SafeAreaView,
  StatusBar,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import tw from '../../../lib/tailwind'
import CountdownTimer from '../../components/CountdownTimer'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import useMeeting from '../../hooks/useMeeting'
import ActionButton from '../../components/ActionButton'
import { FontAwesome } from '@expo/vector-icons'

const ReservationScreen = () => {
  const [message, setMessage] = useState('')
  const [sendRequestIsLoading, setSendRequestIsLoading] = useState(false)

  const { data, cancelMentorReservation, sendRequest } = useMeeting()

  const parsedDate = new Date(data?.reservation?.createdAt)

  const reservationId = data?.reservation?.id

  const handleSendRequest = async (reservationId: string) => {
    if (!data?.reservation?.id) {
      return
    }

    try {
      setSendRequestIsLoading(true)
      await sendRequest({ reservationId, message })
    } catch (error) {
      setSendRequestIsLoading(false)
      console.log(error)
      if ((error as any)?.response?.errors?.[0]?.message === 'Innsufficient funds') {
        Alert.alert('Du har ikke nok minutter. Minimum 15 minutter kreves.')
      } else {
        Alert.alert('Kunne ikke sende forespørsel. Vennligst prøv igjen.')
      }
    }
  }

  const cancelReservation = async () => {
    if (!data?.reservation?.id) {
      return
    }

    try {
      await cancelMentorReservation({ reservationId: data?.reservation?.id })
    } catch (error) {
      throw error
    }
  }

  const onCountdownComplete = async () => {
    await cancelReservation()
  }

  const avatar = data?.reservation?.mentor?.mentorProfile?.avatarUrl
  const defaultImage = require('../../assets/logo.png')

  return (
    <SafeAreaView style={tw.style(`flex-1 bg-white`, { paddingTop: StatusBar.currentHeight })}>
      <KeyboardAwareScrollView>
        <TouchableOpacity onPress={cancelReservation}>
          <Text style={tw`px-3 pt-3 text-blue text-lg font-medium`}>Avbryt</Text>
        </TouchableOpacity>
        <View style={tw`items-center p-3`}>
          <Text style={tw`text-3xl font-bold text-blue mb-3`}>Send forespørsel</Text>
          <Text style={tw`text-base mb-5`}>
            Må sendes innen{' '}
            <CountdownTimer
              parsedDate={parsedDate.getTime()}
              minutes={3}
              onComplete={onCountdownComplete}
            />
          </Text>

          {!!avatar ? (
            <Image source={{ uri: avatar }} style={tw`w-35 h-35 rounded-full`} />
          ) : (
            <View style={tw`w-35 h-35 bg-grey-light rounded-full grid items-center`}>
              <FontAwesome name={'user'} size={50} color={'grey'} style={tw`mt-10`} />
            </View>
          )}
          <Text style={tw`text-3xl font-bold mt-5`}>{data?.reservation?.mentor?.firstName}</Text>
          <Text style={tw`text-base text-grey`}>
            {data?.reservation?.mentor?.mentorProfile?.title}
          </Text>
          <TextInput
            style={tw`bg-grey-light w-full rounded-xl p-4 text-base my-5`}
            multiline
            textAlignVertical={'top'}
            numberOfLines={5}
            value={message}
            onChangeText={setMessage}
            placeholder="Hva trenger du hjelp til?"
          />
          <ActionButton
            label={sendRequestIsLoading ? 'Sender forespørsel' : 'Send'}
            disabled={sendRequestIsLoading}
            isLoading={sendRequestIsLoading}
            onPress={() => reservationId && handleSendRequest(reservationId)}
          />
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )
}
export default ReservationScreen
