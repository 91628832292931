import { Audio } from 'expo-av'
import { Camera } from 'expo-camera'
import { useEffect, useState } from 'react'
import { Text, View, Platform } from 'react-native'
import WebView from 'react-native-webview'
import tw from '../../lib/tailwind'
import useMe from '../hooks/useMe'

interface WherebyMeetingProps {
  roomUrl: string
}

const getOrRequestCameraPermission = async (): Promise<boolean> => {
  return Camera.getCameraPermissionsAsync().then(({ granted, canAskAgain }) => {
    if (granted) {
      return true
    } else if (canAskAgain) {
      return Camera.requestCameraPermissionsAsync().then(({ granted }) => granted)
    } else {
      return false
    }
  })
}

const getOrRequestAudioPermission = async (): Promise<boolean> => {
  return Audio.getPermissionsAsync().then(({ granted, canAskAgain }) => {
    if (granted) {
      return true
    } else if (canAskAgain) {
      return Audio.requestPermissionsAsync().then(({ granted }) => granted)
    } else {
      return false
    }
  })
}

const WherebyMeeting = ({ roomUrl }: WherebyMeetingProps) => {
  const [cameraPermissionStatus, setCameraPermissionStatus] = useState(false)
  const [cameraPermissionError, setCameraPermissionError] = useState(false)
  const [audioPermissionStatus, setAudioPermissionStatus] = useState(false)
  const [audioPermissionError, setAudioPermissionError] = useState(false)
  const { me } = useMe()

  // The roomUrl may sometimes already come with query-params attached at the end of it, so we need append the appropriate symbol to our query-params
  const roomParameters = `${
    roomUrl.includes('?')
      ? me?.mentorProfile?.avatarUrl
        ? `&avatarUrl=${me?.mentorProfile?.avatarUrl}&`
        : '&'
      : '?'
  }needancestor&minimal&chat=on&skipMediaPermissionPrompt&lang=nb&timer=off&floatSelf&moreButton=off&topToolbar=off&leaveButton=off&logo=off&people=off&locking=off&settings=off&participantCount=off${
    me?.firstName ? '&displayName=' + me.firstName : ''
  }`

  const isWeb = Platform.OS === 'web'

  useEffect(() => {
    // Get the media permissions
    getOrRequestAudioPermission()
      .then((value) => setAudioPermissionStatus(value))
      .catch((err) => setAudioPermissionError(err))
    getOrRequestCameraPermission()
      .then((value) => setCameraPermissionStatus(value))
      .catch((err) => setCameraPermissionError(err))
  }, [])

  return cameraPermissionStatus && audioPermissionStatus ? (
    isWeb ? (
      <iframe
        src={roomUrl + roomParameters}
        style={tw`flex-1 bg-white`}
        allow="camera; microphone; fullscreen; speaker; display-capture"
      />
    ) : (
      <WebView
        startInLoadingState={true}
        source={{ uri: roomUrl + roomParameters }}
        mediaPlaybackRequiresUserAction={false}
        style={tw`flex-1 bg-white`}
        // iOS specific:
        allowsInlineMediaPlayback
        // Android specific
        javaScriptEnabled
        domStorageEnabled
      />
    )
  ) : (
    <View style={tw`flex-1 bg-white items-center justify-center`}>
      <Text>Venter på kamera- og lyd-tillatelse</Text>
      {cameraPermissionError && <Text>{cameraPermissionError}</Text>}
      {audioPermissionError && <Text>{audioPermissionError}</Text>}
      {(cameraPermissionError || audioPermissionError) && (
        <Text>
          Vennligst gi appen tillatelse til å ta opp lyd og bruke kamera for å bli med i møterommet
        </Text>
      )}
    </View>
  )
}

export default WherebyMeeting
