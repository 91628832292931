import React from 'react'
import { SafeAreaView, ScrollView, Text } from 'react-native'
import tw from '../../../lib/tailwind'

const AppAboutMentor = ({ navigation }: any) => {
  const mentorFee = 375
  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <ScrollView style={tw`flex-1 mx-3 my-5`}>
        <Text style={tw`text-lg font-bold`}>Min Mentor – Bli en mentor med et tastetrykk</Text>
        <Text style={tw`text-base pt-4 pb-1 font-bold`}>1. Betingelser</Text>
        <Text style={tw`text-base py-2`}>
          I Min Mentor AS tjener du {mentorFee} kr. pr. time. Du er selvstendig oppdragstaker og
          bestemmer selv når du ønsker å jobbe.
        </Text>
        <Text style={tw`text-base pt-2 pb-1`}>I Min Mentor får du:</Text>
        <Text style={tw`text-base pl-3`}>- Godt betalt</Text>
        <Text style={tw`text-base pl-3`}>- Stor fleksibilitet</Text>
        <Text style={tw`text-base pl-3`}>- En givende jobb som skaper glede og engasjement</Text>
        <Text style={tw`text-base pl-3`}>- Styrket CVen</Text>
        <Text style={tw`text-base pl-3`}>- Mulighet for referanser</Text>
        <Text style={tw`text-base pl-3`}>- Kurs og oppfølging</Text>
        <Text style={tw`text-base pl-3`}>- Hjelp og støtte fra våre fagledere</Text>
        <Text style={tw`text-base pl-3`}>- Sosiale arrangementer</Text>
        <Text style={tw`text-base pl-3`}>- Hjelpemidler og gadgets</Text>
        <Text style={tw`text-base py-2`}>
          Du har mulighet til å bli favorittmentor og følge den samme eleven over flere år og være
          med på en reise hvor eleven styrker sin kompetanse og oppnår bedre karakterer og når sine
          mål.
        </Text>
        <Text style={tw`text-base pt-4 pb-1 font-bold`}>
          2. Hvordan blir jeg lærer og hvordan foregår undervisingen?
        </Text>
        <Text style={tw`text-base py-2`}>
          Du registrerer deg enkelt som lærer i appen. Her laster du også opp bilde, vedlegger CV,
          karakterutskrifter og politiattester. I registreringen kan du selv velge hvilke fag og
          emner du ønsker å undervise i. I tillegg oppretter du der en Stripe-konto som brukes for
          utbetaling av penger. Etter dette vil du snarlig få beskjed om du er godkjent av
          administrator.
        </Text>
        <Text style={tw`text-base py-2`}>
          Etter godkjennelsen kan du føre deg opp som tilgjengelig når det passer.
        </Text>
        <Text style={tw`text-base py-2`}>
          Når en elev har bestilt undervisning hos deg, får du beskjed i appen og du må da innen 15
          minutter forberede deg til undervisningen og godta forespørselen.
        </Text>
        <Text style={tw`text-base py-2`}>
          Videosamtalen kan foregå via innlogging på nettsiden, nettbrett eller telefon.
        </Text>
        <Text style={tw`text-base py-2`}>
          Begge parter kan avslutte møtet når som helst, og møtet avsluttes automatisk dersom eleven
          går tom for tid. Du får da bekreftelse på dette, samt hva du har tjent på undervisningen.
          Vederlaget blir blir utbetalt til din bankkonto. Du kan hele tiden følge med på
          bestillinger og utbetalinger på «din side» i appen.
        </Text>
        <Text style={tw`text-base py-2`}>
          For utfyllende informasjon, besøk vår hjemmeside www.minmentor.no.
        </Text>
        <Text style={tw`text-base py-2`}>
          Har du spørsmål til oss kan du sendes dem på e-post til support@minmentor.no, fylle ut
          kontaktskjemaet på minmentor.no eller ringe oss på telefon 45 80 76 00.
        </Text>
        <Text style={tw`text-base py-2`}>Vel møtt!</Text>
      </ScrollView>
    </SafeAreaView>
  )
}

export default AppAboutMentor
