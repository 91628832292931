import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
import { ClientError } from 'graphql-request/dist/types';
import useSWR, { SWRConfiguration as SWRConfigInterface, Key as SWRKeyInterface } from 'swr';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
};

export type CustomObject = {
  __typename?: 'CustomObject';
  name: Scalars['String'];
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['ID'];
  originalFilename: Scalars['String'];
  url: Scalars['String'];
};

export enum EducationLevel {
  Elementary = 'ELEMENTARY',
  HighSchool = 'HIGH_SCHOOL',
  MiddleSchool = 'MIDDLE_SCHOOL'
}

export type FinancialTransaction = {
  __typename?: 'FinancialTransaction';
  externalId: Scalars['String'];
  id: Scalars['ID'];
  order: Order;
  priceNok: Scalars['Int'];
  status: PaymentStatusValue;
  type: FinancialTransactionTypeValue;
};

export enum FinancialTransactionTypeValue {
  Payment = 'PAYMENT'
}

export type GlobalSettings = {
  __typename?: 'GlobalSettings';
  aboutMentor?: Maybe<Scalars['String']>;
  aboutStudent?: Maybe<Scalars['String']>;
  autoPayoutsIsOn: Scalars['Boolean'];
  id: Scalars['String'];
};

export type InitiatePaymentResponse = {
  __typename?: 'InitiatePaymentResponse';
  orderId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type Meeting = {
  __typename?: 'Meeting';
  createdAt: Scalars['Date'];
  endedAt?: Maybe<Scalars['Date']>;
  host?: Maybe<User>;
  hostId: Scalars['String'];
  hostRoomUrl: Scalars['String'];
  id: Scalars['String'];
  meetingId: Scalars['String'];
  participants: Array<User>;
  roomUrl: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type MentorProfile = {
  __typename?: 'MentorProfile';
  avatarUrl?: Maybe<Scalars['String']>;
  averageRating?: Maybe<Scalars['Float']>;
  bankAccountNumber?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<Document>>;
  hasStripeAccount: Scalars['Boolean'];
  id: Scalars['ID'];
  isApproved: Scalars['Boolean'];
  isAvailable: Scalars['Boolean'];
  isFavorited: Scalars['Boolean'];
  meetingsCount?: Maybe<Scalars['Int']>;
  moneyEarned?: Maybe<Scalars['Float']>;
  orgNumber?: Maybe<Scalars['String']>;
  ratingsCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  topics: Array<Topic>;
  user?: Maybe<User>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Accept request from a student and start a meeting */
  acceptRequest?: Maybe<Reservation>;
  /** Cancel a reservation */
  cancelReservation?: Maybe<Reservation>;
  createRating?: Maybe<Rating>;
  /** Create a new reservation */
  createReservation?: Maybe<Reservation>;
  createStripeAccount: MentorProfile;
  /** Decline a request as a mentor */
  declineRequest?: Maybe<Reservation>;
  deleteDocument: Document;
  /** End meeting */
  endMeeting?: Maybe<Meeting>;
  initiatePayment: InitiatePaymentResponse;
  /** Select topics */
  selectTopics: Array<Topic>;
  /** Send request to a mentor */
  sendRequest?: Maybe<Reservation>;
  /** Toggle mentor availability */
  setMentorAvailability: MentorProfile;
  setUserRole: User;
  skipRating: Meeting;
  testPushNotification: Scalars['Boolean'];
  toggleFavoriteMentor?: Maybe<User>;
  /** Update mentor profile */
  updateMentorProfile?: Maybe<MentorProfile>;
  /** Update mentor profile avatar */
  updateMentorProfileAvatar?: Maybe<MentorProfile>;
  updatePushToken: User;
  /** Upload a document */
  uploadDocument: Document;
};


export type MutationAcceptRequestArgs = {
  message?: InputMaybe<Scalars['String']>;
  reservationId: Scalars['String'];
};


export type MutationCancelReservationArgs = {
  reservationId: Scalars['String'];
};


export type MutationCreateRatingArgs = {
  meetingId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
};


export type MutationCreateReservationArgs = {
  mentorId: Scalars['String'];
};


export type MutationDeclineRequestArgs = {
  message?: InputMaybe<Scalars['String']>;
  reservationId: Scalars['String'];
};


export type MutationDeleteDocumentArgs = {
  id: Scalars['String'];
};


export type MutationInitiatePaymentArgs = {
  amount?: InputMaybe<Scalars['Int']>;
  platform?: Scalars['String'];
  productId: Scalars['String'];
};


export type MutationSelectTopicsArgs = {
  topicIds: Array<Scalars['String']>;
};


export type MutationSendRequestArgs = {
  message?: InputMaybe<Scalars['String']>;
  reservationId: Scalars['String'];
};


export type MutationSetMentorAvailabilityArgs = {
  availability: Scalars['Boolean'];
};


export type MutationSetUserRoleArgs = {
  role: RoleValue;
};


export type MutationSkipRatingArgs = {
  meetingId: Scalars['String'];
};


export type MutationToggleFavoriteMentorArgs = {
  mentorId: Scalars['String'];
};


export type MutationUpdateMentorProfileArgs = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  orgNumber?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateMentorProfileAvatarArgs = {
  avatarUrl?: InputMaybe<Scalars['String']>;
};


export type MutationUpdatePushTokenArgs = {
  token?: InputMaybe<Scalars['String']>;
};


export type MutationUploadDocumentArgs = {
  file: Scalars['String'];
  fileName?: InputMaybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  products: Array<OrderedProduct>;
  timeTransaction?: Maybe<TimeTransaction>;
  transactions: Array<FinancialTransaction>;
  updatedAt: Scalars['Date'];
  user: User;
};

export type OrderedProduct = {
  __typename?: 'OrderedProduct';
  amount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  durationInSeconds?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Order;
  priceNok: Scalars['Int'];
};

export type PaymentResponse = {
  __typename?: 'PaymentResponse';
  url: Scalars['String'];
};

export enum PaymentStatusValue {
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Pending = 'PENDING'
}

export type Payout = {
  __typename?: 'Payout';
  externalId: Scalars['String'];
  id: Scalars['ID'];
  mentor: MentorProfile;
  timeTransaction?: Maybe<TimeTransaction>;
};

export type Product = {
  __typename?: 'Product';
  description?: Maybe<Scalars['String']>;
  durationInSeconds?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  priceNok: Scalars['Int'];
};

export type Query = {
  __typename?: 'Query';
  documents?: Maybe<Array<Document>>;
  /** Get the global settings of the system */
  globalSettings?: Maybe<GlobalSettings>;
  /** Get the current user */
  me?: Maybe<User>;
  /** Get any active meeting */
  meeting?: Maybe<Meeting>;
  /** Get a mentor by their id */
  mentor?: Maybe<MentorProfile>;
  /** Get a list of your mentoring meeting history */
  mentorMeetingHistory?: Maybe<Array<TimeTransaction>>;
  /** Get a list of ratings and messages. */
  mentorRatings?: Maybe<Array<Rating>>;
  /** Get a list of currently active mentors */
  mentors?: Maybe<Array<MentorProfile>>;
  /** Get users mentor profile */
  myMentor?: Maybe<MentorProfile>;
  /** Get a single order by id */
  order?: Maybe<Order>;
  /** Get payment status of an order */
  orderPaymentStatus?: Maybe<PaymentStatusValue>;
  /** Get all of my orders */
  orders: Array<Order>;
  /** Get a list of products */
  products?: Maybe<Array<Product>>;
  /** Get the remaining time of the current user. */
  remainingTime: Scalars['Int'];
  /** Get the active reservations */
  request?: Maybe<Reservation>;
  /** Get the active reservations */
  reservation?: Maybe<Reservation>;
  selectedTopics?: Maybe<Array<Topic>>;
  stripeStatus: StripeStatusResponse;
  /** Get a list of your meeting history */
  studentMeetingHistory?: Maybe<Array<TimeTransaction>>;
  /** Get a list of your incoming time */
  studentOrderHistory?: Maybe<Array<TimeTransaction>>;
  /** Get a list of topics */
  topics?: Maybe<Array<Topic>>;
};


export type QueryMentorArgs = {
  mentorId: Scalars['String'];
};


export type QueryMentorRatingsArgs = {
  take?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};


export type QueryMentorsArgs = {
  topicIds?: InputMaybe<Array<Scalars['String']>>;
};


export type QueryOrderArgs = {
  id: Scalars['String'];
};


export type QueryOrderPaymentStatusArgs = {
  orderId: Scalars['String'];
};


export type QueryStripeStatusArgs = {
  isWeb?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTopicsArgs = {
  educationLevel?: InputMaybe<Scalars['String']>;
};

export type Rating = {
  __typename?: 'Rating';
  createdAt?: Maybe<Scalars['Date']>;
  creator?: Maybe<User>;
  creatorId: Scalars['String'];
  id: Scalars['ID'];
  meeting?: Maybe<Meeting>;
  message?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
};

export type Reservation = {
  __typename?: 'Reservation';
  createdAt: Scalars['Date'];
  id: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  mentor: User;
  message?: Maybe<Scalars['String']>;
  requestSent: Scalars['Boolean'];
  requestSentAt?: Maybe<Scalars['Date']>;
  student: User;
  updatedAt: Scalars['Date'];
};

export enum RoleValue {
  Mentor = 'MENTOR',
  Student = 'STUDENT'
}

export type StripeStatusResponse = {
  __typename?: 'StripeStatusResponse';
  dashboardUrl?: Maybe<Scalars['String']>;
  onBoardingUrl?: Maybe<Scalars['String']>;
  status: StripeStatusType;
};

export enum StripeStatusType {
  Complete = 'COMPLETE',
  NotConnected = 'NOT_CONNECTED',
  Onboarding = 'ONBOARDING',
  PendingApproval = 'PENDING_APPROVAL'
}

export type TimeTransaction = {
  __typename?: 'TimeTransaction';
  createdAt: Scalars['Date'];
  durationInSeconds: Scalars['Int'];
  id: Scalars['ID'];
  meeting?: Maybe<Meeting>;
  order?: Maybe<Order>;
  payout?: Maybe<Payout>;
  receiver?: Maybe<User>;
  sender?: Maybe<User>;
  type: TimeTransactionTypeValue;
};

export enum TimeTransactionTypeValue {
  Incoming = 'INCOMING',
  Mentoring = 'MENTORING',
  Outgoing = 'OUTGOING'
}

export type Topic = {
  __typename?: 'Topic';
  id: Scalars['String'];
  level: EducationLevel;
  name: Scalars['String'];
  parentTopicId?: Maybe<Scalars['String']>;
  subTopics: Array<Topic>;
  subTopicsCount: Scalars['Int'];
};

export type User = {
  __typename?: 'User';
  birthDate?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  favoriteMentor?: Maybe<MentorProfile>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  mentorProfile?: Maybe<MentorProfile>;
  phone: Scalars['String'];
  pushToken?: Maybe<Scalars['String']>;
  role?: Maybe<RoleValue>;
};

export type CreateReservationMutationVariables = Exact<{
  mentorId: Scalars['String'];
}>;


export type CreateReservationMutation = { __typename?: 'Mutation', createReservation?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null };

export type CancelReservationMutationVariables = Exact<{
  reservationId: Scalars['String'];
}>;


export type CancelReservationMutation = { __typename?: 'Mutation', cancelReservation?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null };

export type SendRequestMutationVariables = Exact<{
  reservationId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
}>;


export type SendRequestMutation = { __typename?: 'Mutation', sendRequest?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null };

export type DeclineRequestMutationVariables = Exact<{
  reservationId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
}>;


export type DeclineRequestMutation = { __typename?: 'Mutation', declineRequest?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null };

export type AcceptRequestMutationVariables = Exact<{
  reservationId: Scalars['String'];
}>;


export type AcceptRequestMutation = { __typename?: 'Mutation', acceptRequest?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null };

export type EndMeetingMutationVariables = Exact<{ [key: string]: never; }>;


export type EndMeetingMutation = { __typename?: 'Mutation', endMeeting?: { __typename?: 'Meeting', id: string, roomUrl: string, hostRoomUrl: string, endedAt?: any | null, createdAt: any } | null };

export type CreateRatingMutationVariables = Exact<{
  message?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<Scalars['Int']>;
  meetingId: Scalars['String'];
}>;


export type CreateRatingMutation = { __typename?: 'Mutation', createRating?: { __typename?: 'Rating', id: string } | null };

export type SkipRatingMutationVariables = Exact<{
  meetingId: Scalars['String'];
}>;


export type SkipRatingMutation = { __typename?: 'Mutation', skipRating: { __typename?: 'Meeting', id: string, roomUrl: string, hostRoomUrl: string, endedAt?: any | null, createdAt: any } };

export type CreateStripeAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateStripeAccountMutation = { __typename?: 'Mutation', createStripeAccount: { __typename?: 'MentorProfile', id: string } };

export type ToggleFavoriteMentorMutationVariables = Exact<{
  mentorId: Scalars['String'];
}>;


export type ToggleFavoriteMentorMutation = { __typename?: 'Mutation', toggleFavoriteMentor?: { __typename?: 'User', id: string, mentorProfile?: { __typename?: 'MentorProfile', isFavorited: boolean } | null } | null };

export type InitiatePaymentMutationVariables = Exact<{
  productId: Scalars['String'];
  amount?: InputMaybe<Scalars['Int']>;
  platform?: InputMaybe<Scalars['String']>;
}>;


export type InitiatePaymentMutation = { __typename?: 'Mutation', initiatePayment: { __typename?: 'InitiatePaymentResponse', url: string, orderId?: string | null } };

export type SelectTopicsMutationVariables = Exact<{
  topicIds: Array<Scalars['String']> | Scalars['String'];
}>;


export type SelectTopicsMutation = { __typename?: 'Mutation', selectTopics: Array<{ __typename?: 'Topic', id: string, name: string }> };

export type SetUserRoleMutationVariables = Exact<{
  role: RoleValue;
}>;


export type SetUserRoleMutation = { __typename?: 'Mutation', setUserRole: { __typename?: 'User', id: string, phone: string, email: string, firstName: string, lastName: string, birthDate?: string | null, role?: RoleValue | null, pushToken?: string | null, mentorProfile?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null } };

export type SetMentorAvailabilityMutationVariables = Exact<{
  availability: Scalars['Boolean'];
}>;


export type SetMentorAvailabilityMutation = { __typename?: 'Mutation', setMentorAvailability: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } };

export type UpdateMentorProfileMutationVariables = Exact<{
  title?: InputMaybe<Scalars['String']>;
  orgNumber?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  bankAccountNumber?: InputMaybe<Scalars['String']>;
}>;


export type UpdateMentorProfileMutation = { __typename?: 'Mutation', updateMentorProfile?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null };

export type UpdatePushTokenMutationVariables = Exact<{
  token?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePushTokenMutation = { __typename?: 'Mutation', updatePushToken: { __typename?: 'User', id: string, phone: string, email: string, firstName: string, lastName: string, birthDate?: string | null, role?: RoleValue | null, pushToken?: string | null, mentorProfile?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null } };

export type TestPushNotificationMutationVariables = Exact<{ [key: string]: never; }>;


export type TestPushNotificationMutation = { __typename?: 'Mutation', testPushNotification: boolean };

export type UploadDocumentMutationVariables = Exact<{
  file: Scalars['String'];
  fileName?: InputMaybe<Scalars['String']>;
}>;


export type UploadDocumentMutation = { __typename?: 'Mutation', uploadDocument: { __typename?: 'Document', id: string, originalFilename: string, url: string } };

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteDocumentMutation = { __typename?: 'Mutation', deleteDocument: { __typename?: 'Document', id: string, originalFilename: string, url: string } };

export type GlobalSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GlobalSettingsQuery = { __typename?: 'Query', globalSettings?: { __typename?: 'GlobalSettings', autoPayoutsIsOn: boolean } | null };

export type ReservationFragmentFragment = { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } };

export type MeetingFragmentFragment = { __typename?: 'Meeting', id: string, roomUrl: string, hostRoomUrl: string, endedAt?: any | null, createdAt: any };

export type ReservationQueryVariables = Exact<{ [key: string]: never; }>;


export type ReservationQuery = { __typename?: 'Query', reservation?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null };

export type RequestQueryVariables = Exact<{ [key: string]: never; }>;


export type RequestQuery = { __typename?: 'Query', request?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null };

export type MeetingQueryVariables = Exact<{ [key: string]: never; }>;


export type MeetingQuery = { __typename?: 'Query', meeting?: { __typename?: 'Meeting', id: string, roomUrl: string, hostRoomUrl: string, endedAt?: any | null, createdAt: any } | null, reservation?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null, request?: { __typename?: 'Reservation', id: string, requestSent: boolean, requestSentAt?: any | null, createdAt: any, message?: string | null, mentor: { __typename?: 'User', firstName: string, id: string, mentorProfile?: { __typename?: 'MentorProfile', avatarUrl?: string | null, title?: string | null } | null }, student: { __typename?: 'User', id: string, firstName: string } } | null };

export type MentorsQueryVariables = Exact<{
  topicIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type MentorsQuery = { __typename?: 'Query', mentors?: Array<{ __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, user?: { __typename?: 'User', id: string, phone: string, email: string, firstName: string, lastName: string, birthDate?: string | null, role?: RoleValue | null, pushToken?: string | null, mentorProfile?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null } | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> }> | null };

export type MentorQueryVariables = Exact<{
  mentorId: Scalars['String'];
}>;


export type MentorQuery = { __typename?: 'Query', mentor?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, user?: { __typename?: 'User', id: string, phone: string, email: string, firstName: string, lastName: string, birthDate?: string | null, role?: RoleValue | null, pushToken?: string | null, mentorProfile?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null } | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null };

export type MyMentorQueryVariables = Exact<{ [key: string]: never; }>;


export type MyMentorQuery = { __typename?: 'Query', myMentor?: { __typename?: 'MentorProfile', meetingsCount?: number | null, moneyEarned?: number | null, id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null };

export type StripeStatusQueryVariables = Exact<{
  isWeb: Scalars['Boolean'];
}>;


export type StripeStatusQuery = { __typename?: 'Query', stripeStatus: { __typename?: 'StripeStatusResponse', status: StripeStatusType, onBoardingUrl?: string | null, dashboardUrl?: string | null } };

export type OrderQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrderQuery = { __typename?: 'Query', order?: { __typename?: 'Order', id: string } | null };

export type OrderPaymentStatusQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrderPaymentStatusQuery = { __typename?: 'Query', orderPaymentStatus?: PaymentStatusValue | null };

export type OrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrdersQuery = { __typename?: 'Query', orders: Array<{ __typename?: 'Order', createdAt: any, id: string, transactions: Array<{ __typename?: 'FinancialTransaction', id: string, priceNok: number, status: PaymentStatusValue, externalId: string }>, products: Array<{ __typename?: 'OrderedProduct', name: string, description?: string | null, amount: number }>, timeTransaction?: { __typename?: 'TimeTransaction', id: string, durationInSeconds: number } | null }> };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', products?: Array<{ __typename?: 'Product', id: string, name: string, description?: string | null, priceNok: number, durationInSeconds?: number | null }> | null };

export type MentorRatingsQueryVariables = Exact<{
  userId: Scalars['String'];
  take?: InputMaybe<Scalars['Int']>;
}>;


export type MentorRatingsQuery = { __typename?: 'Query', mentorRatings?: Array<{ __typename?: 'Rating', id: string, createdAt?: any | null, message?: string | null, rating?: number | null, creator?: { __typename?: 'User', id: string, firstName: string } | null }> | null };

export type MentorMeetingHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type MentorMeetingHistoryQuery = { __typename?: 'Query', mentorMeetingHistory?: Array<{ __typename?: 'TimeTransaction', id: string, durationInSeconds: number, payout?: { __typename?: 'Payout', id: string } | null, meeting?: { __typename?: 'Meeting', createdAt: any, endedAt?: any | null, participants: Array<{ __typename?: 'User', firstName: string }> } | null }> | null };

export type StudentMeetingHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentMeetingHistoryQuery = { __typename?: 'Query', studentMeetingHistory?: Array<{ __typename?: 'TimeTransaction', id: string, durationInSeconds: number, meeting?: { __typename?: 'Meeting', createdAt: any, endedAt?: any | null, participants: Array<{ __typename?: 'User', firstName: string }>, host?: { __typename?: 'User', firstName: string } | null } | null }> | null };

export type StudentOrderHistoryQueryVariables = Exact<{ [key: string]: never; }>;


export type StudentOrderHistoryQuery = { __typename?: 'Query', studentOrderHistory?: Array<{ __typename?: 'TimeTransaction', id: string, durationInSeconds: number, order?: { __typename?: 'Order', createdAt: any, id: string, transactions: Array<{ __typename?: 'FinancialTransaction', id: string, priceNok: number, status: PaymentStatusValue, externalId: string }>, products: Array<{ __typename?: 'OrderedProduct', name: string, description?: string | null, amount: number }> } | null }> | null };

export type TopicsQueryVariables = Exact<{
  educationLevel?: InputMaybe<Scalars['String']>;
}>;


export type TopicsQuery = { __typename?: 'Query', topics?: Array<{ __typename?: 'Topic', id: string, level: EducationLevel, name: string, subTopicsCount: number, parentTopicId?: string | null, subTopics: Array<{ __typename?: 'Topic', id: string, name: string }> }> | null };

export type SelectedTopicsQueryVariables = Exact<{ [key: string]: never; }>;


export type SelectedTopicsQuery = { __typename?: 'Query', selectedTopics?: Array<{ __typename?: 'Topic', id: string }> | null };

export type UserFragmentFragment = { __typename?: 'User', id: string, phone: string, email: string, firstName: string, lastName: string, birthDate?: string | null, role?: RoleValue | null, pushToken?: string | null, mentorProfile?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null };

export type MentorProfileFragmentFragment = { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> };

export type MentorProfileAndUserFragmentFragment = { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, user?: { __typename?: 'User', id: string, phone: string, email: string, firstName: string, lastName: string, birthDate?: string | null, role?: RoleValue | null, pushToken?: string | null, mentorProfile?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null } | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> };

export type DocumentFragmentFragment = { __typename?: 'Document', id: string, originalFilename: string, url: string };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, phone: string, email: string, firstName: string, lastName: string, birthDate?: string | null, role?: RoleValue | null, pushToken?: string | null, mentorProfile?: { __typename?: 'MentorProfile', id: string, avatarUrl?: string | null, bio?: string | null, isAvailable: boolean, isApproved: boolean, isFavorited: boolean, orgNumber?: string | null, title?: string | null, ratingsCount?: number | null, averageRating?: number | null, hasStripeAccount: boolean, bankAccountNumber?: string | null, topics: Array<{ __typename?: 'Topic', id: string, name: string }> } | null } | null };

export type RemainingTimeQueryVariables = Exact<{ [key: string]: never; }>;


export type RemainingTimeQuery = { __typename?: 'Query', remainingTime: number };

export type DocumentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DocumentsQuery = { __typename?: 'Query', documents?: Array<{ __typename?: 'Document', id: string, originalFilename: string, url: string }> | null };

export const ReservationFragmentFragmentDoc = gql`
    fragment ReservationFragment on Reservation {
  id
  requestSent
  requestSentAt
  createdAt
  mentor {
    firstName
    id
    mentorProfile {
      avatarUrl
      title
    }
  }
  student {
    id
    firstName
  }
  message
}
    `;
export const MeetingFragmentFragmentDoc = gql`
    fragment MeetingFragment on Meeting {
  id
  roomUrl
  hostRoomUrl
  endedAt
  createdAt
}
    `;
export const MentorProfileFragmentFragmentDoc = gql`
    fragment MentorProfileFragment on MentorProfile {
  id
  avatarUrl
  bio
  isAvailable
  isApproved
  isFavorited
  orgNumber
  title
  ratingsCount
  averageRating
  hasStripeAccount
  bankAccountNumber
  topics {
    id
    name
  }
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  phone
  email
  firstName
  lastName
  birthDate
  role
  pushToken
  mentorProfile {
    ...MentorProfileFragment
  }
}
    ${MentorProfileFragmentFragmentDoc}`;
export const MentorProfileAndUserFragmentFragmentDoc = gql`
    fragment MentorProfileAndUserFragment on MentorProfile {
  ...MentorProfileFragment
  user {
    ...UserFragment
  }
}
    ${MentorProfileFragmentFragmentDoc}
${UserFragmentFragmentDoc}`;
export const DocumentFragmentFragmentDoc = gql`
    fragment DocumentFragment on Document {
  id
  originalFilename
  url
}
    `;
export const CreateReservationDocument = gql`
    mutation createReservation($mentorId: String!) {
  createReservation(mentorId: $mentorId) {
    ...ReservationFragment
  }
}
    ${ReservationFragmentFragmentDoc}`;
export const CancelReservationDocument = gql`
    mutation cancelReservation($reservationId: String!) {
  cancelReservation(reservationId: $reservationId) {
    ...ReservationFragment
  }
}
    ${ReservationFragmentFragmentDoc}`;
export const SendRequestDocument = gql`
    mutation sendRequest($reservationId: String!, $message: String) {
  sendRequest(reservationId: $reservationId, message: $message) {
    ...ReservationFragment
  }
}
    ${ReservationFragmentFragmentDoc}`;
export const DeclineRequestDocument = gql`
    mutation declineRequest($reservationId: String!, $message: String) {
  declineRequest(reservationId: $reservationId, message: $message) {
    ...ReservationFragment
  }
}
    ${ReservationFragmentFragmentDoc}`;
export const AcceptRequestDocument = gql`
    mutation acceptRequest($reservationId: String!) {
  acceptRequest(reservationId: $reservationId) {
    ...ReservationFragment
  }
}
    ${ReservationFragmentFragmentDoc}`;
export const EndMeetingDocument = gql`
    mutation endMeeting {
  endMeeting {
    ...MeetingFragment
  }
}
    ${MeetingFragmentFragmentDoc}`;
export const CreateRatingDocument = gql`
    mutation createRating($message: String, $rating: Int, $meetingId: String!) {
  createRating(rating: $rating, meetingId: $meetingId, message: $message) {
    id
  }
}
    `;
export const SkipRatingDocument = gql`
    mutation skipRating($meetingId: String!) {
  skipRating(meetingId: $meetingId) {
    ...MeetingFragment
  }
}
    ${MeetingFragmentFragmentDoc}`;
export const CreateStripeAccountDocument = gql`
    mutation createStripeAccount {
  createStripeAccount {
    id
  }
}
    `;
export const ToggleFavoriteMentorDocument = gql`
    mutation toggleFavoriteMentor($mentorId: String!) {
  toggleFavoriteMentor(mentorId: $mentorId) {
    id
    mentorProfile {
      isFavorited
    }
  }
}
    `;
export const InitiatePaymentDocument = gql`
    mutation initiatePayment($productId: String!, $amount: Int, $platform: String) {
  initiatePayment(productId: $productId, amount: $amount, platform: $platform) {
    url
    orderId
  }
}
    `;
export const SelectTopicsDocument = gql`
    mutation selectTopics($topicIds: [String!]!) {
  selectTopics(topicIds: $topicIds) {
    id
    name
  }
}
    `;
export const SetUserRoleDocument = gql`
    mutation setUserRole($role: RoleValue!) {
  setUserRole(role: $role) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export const SetMentorAvailabilityDocument = gql`
    mutation setMentorAvailability($availability: Boolean!) {
  setMentorAvailability(availability: $availability) {
    ...MentorProfileFragment
  }
}
    ${MentorProfileFragmentFragmentDoc}`;
export const UpdateMentorProfileDocument = gql`
    mutation updateMentorProfile($title: String, $orgNumber: String, $bio: String, $avatarUrl: String, $bankAccountNumber: String) {
  updateMentorProfile(
    title: $title
    orgNumber: $orgNumber
    bio: $bio
    avatarUrl: $avatarUrl
    bankAccountNumber: $bankAccountNumber
  ) {
    ...MentorProfileFragment
  }
}
    ${MentorProfileFragmentFragmentDoc}`;
export const UpdatePushTokenDocument = gql`
    mutation updatePushToken($token: String) {
  updatePushToken(token: $token) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;
export const TestPushNotificationDocument = gql`
    mutation testPushNotification {
  testPushNotification
}
    `;
export const UploadDocumentDocument = gql`
    mutation uploadDocument($file: String!, $fileName: String) {
  uploadDocument(file: $file, fileName: $fileName) {
    ...DocumentFragment
  }
}
    ${DocumentFragmentFragmentDoc}`;
export const DeleteDocumentDocument = gql`
    mutation deleteDocument($id: String!) {
  deleteDocument(id: $id) {
    ...DocumentFragment
  }
}
    ${DocumentFragmentFragmentDoc}`;
export const GlobalSettingsDocument = gql`
    query globalSettings {
  globalSettings {
    autoPayoutsIsOn
  }
}
    `;
export const ReservationDocument = gql`
    query Reservation {
  reservation {
    ...ReservationFragment
  }
}
    ${ReservationFragmentFragmentDoc}`;
export const RequestDocument = gql`
    query Request {
  request {
    ...ReservationFragment
  }
}
    ${ReservationFragmentFragmentDoc}`;
export const MeetingDocument = gql`
    query Meeting {
  meeting {
    ...MeetingFragment
  }
  reservation {
    ...ReservationFragment
  }
  request {
    ...ReservationFragment
  }
}
    ${MeetingFragmentFragmentDoc}
${ReservationFragmentFragmentDoc}`;
export const MentorsDocument = gql`
    query mentors($topicIds: [String!] = "") {
  mentors(topicIds: $topicIds) {
    ...MentorProfileAndUserFragment
  }
}
    ${MentorProfileAndUserFragmentFragmentDoc}`;
export const MentorDocument = gql`
    query mentor($mentorId: String!) {
  mentor(mentorId: $mentorId) {
    ...MentorProfileAndUserFragment
  }
}
    ${MentorProfileAndUserFragmentFragmentDoc}`;
export const MyMentorDocument = gql`
    query myMentor {
  myMentor {
    ...MentorProfileFragment
    meetingsCount
    moneyEarned
  }
}
    ${MentorProfileFragmentFragmentDoc}`;
export const StripeStatusDocument = gql`
    query stripeStatus($isWeb: Boolean!) {
  stripeStatus(isWeb: $isWeb) {
    status
    onBoardingUrl
    dashboardUrl
  }
}
    `;
export const OrderDocument = gql`
    query order($id: String!) {
  order(id: $id) {
    id
  }
}
    `;
export const OrderPaymentStatusDocument = gql`
    query orderPaymentStatus($id: String!) {
  orderPaymentStatus(orderId: $id)
}
    `;
export const OrdersDocument = gql`
    query orders {
  orders {
    createdAt
    id
    transactions {
      id
      priceNok
      status
      externalId
    }
    products {
      name
      description
      amount
    }
    timeTransaction {
      id
      durationInSeconds
    }
  }
}
    `;
export const ProductsDocument = gql`
    query Products {
  products {
    id
    name
    description
    priceNok
    durationInSeconds
  }
}
    `;
export const MentorRatingsDocument = gql`
    query mentorRatings($userId: String!, $take: Int) {
  mentorRatings(take: $take, userId: $userId) {
    id
    createdAt
    message
    rating
    creator {
      id
      firstName
    }
  }
}
    `;
export const MentorMeetingHistoryDocument = gql`
    query mentorMeetingHistory {
  mentorMeetingHistory {
    id
    durationInSeconds
    payout {
      id
    }
    meeting {
      createdAt
      endedAt
      participants {
        firstName
      }
    }
  }
}
    `;
export const StudentMeetingHistoryDocument = gql`
    query studentMeetingHistory {
  studentMeetingHistory {
    id
    durationInSeconds
    meeting {
      createdAt
      endedAt
      participants {
        firstName
      }
      host {
        firstName
      }
    }
  }
}
    `;
export const StudentOrderHistoryDocument = gql`
    query studentOrderHistory {
  studentOrderHistory {
    id
    durationInSeconds
    order {
      createdAt
      id
      transactions {
        id
        priceNok
        status
        externalId
      }
      products {
        name
        description
        amount
      }
    }
  }
}
    `;
export const TopicsDocument = gql`
    query Topics($educationLevel: String) {
  topics(educationLevel: $educationLevel) {
    id
    level
    name
    subTopicsCount
    parentTopicId
    subTopics {
      id
      name
    }
  }
}
    `;
export const SelectedTopicsDocument = gql`
    query selectedTopics {
  selectedTopics {
    id
  }
}
    `;
export const MeDocument = gql`
    query me {
  me {
    id
    phone
    email
    firstName
    lastName
    birthDate
    role
    pushToken
    mentorProfile {
      ...MentorProfileFragment
    }
  }
}
    ${MentorProfileFragmentFragmentDoc}`;
export const RemainingTimeDocument = gql`
    query remainingTime {
  remainingTime
}
    `;
export const DocumentsDocument = gql`
    query documents {
  documents {
    ...DocumentFragment
  }
}
    ${DocumentFragmentFragmentDoc}`;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    createReservation(variables: CreateReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateReservationMutation>(CreateReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createReservation', 'mutation');
    },
    cancelReservation(variables: CancelReservationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CancelReservationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CancelReservationMutation>(CancelReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'cancelReservation', 'mutation');
    },
    sendRequest(variables: SendRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SendRequestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SendRequestMutation>(SendRequestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'sendRequest', 'mutation');
    },
    declineRequest(variables: DeclineRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeclineRequestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeclineRequestMutation>(DeclineRequestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'declineRequest', 'mutation');
    },
    acceptRequest(variables: AcceptRequestMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<AcceptRequestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<AcceptRequestMutation>(AcceptRequestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'acceptRequest', 'mutation');
    },
    endMeeting(variables?: EndMeetingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<EndMeetingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<EndMeetingMutation>(EndMeetingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'endMeeting', 'mutation');
    },
    createRating(variables: CreateRatingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateRatingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateRatingMutation>(CreateRatingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createRating', 'mutation');
    },
    skipRating(variables: SkipRatingMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SkipRatingMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SkipRatingMutation>(SkipRatingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'skipRating', 'mutation');
    },
    createStripeAccount(variables?: CreateStripeAccountMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateStripeAccountMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateStripeAccountMutation>(CreateStripeAccountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createStripeAccount', 'mutation');
    },
    toggleFavoriteMentor(variables: ToggleFavoriteMentorMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ToggleFavoriteMentorMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ToggleFavoriteMentorMutation>(ToggleFavoriteMentorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'toggleFavoriteMentor', 'mutation');
    },
    initiatePayment(variables: InitiatePaymentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<InitiatePaymentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<InitiatePaymentMutation>(InitiatePaymentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'initiatePayment', 'mutation');
    },
    selectTopics(variables: SelectTopicsMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SelectTopicsMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SelectTopicsMutation>(SelectTopicsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'selectTopics', 'mutation');
    },
    setUserRole(variables: SetUserRoleMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetUserRoleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetUserRoleMutation>(SetUserRoleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'setUserRole', 'mutation');
    },
    setMentorAvailability(variables: SetMentorAvailabilityMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SetMentorAvailabilityMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<SetMentorAvailabilityMutation>(SetMentorAvailabilityDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'setMentorAvailability', 'mutation');
    },
    updateMentorProfile(variables?: UpdateMentorProfileMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdateMentorProfileMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdateMentorProfileMutation>(UpdateMentorProfileDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateMentorProfile', 'mutation');
    },
    updatePushToken(variables?: UpdatePushTokenMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UpdatePushTokenMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UpdatePushTokenMutation>(UpdatePushTokenDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updatePushToken', 'mutation');
    },
    testPushNotification(variables?: TestPushNotificationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TestPushNotificationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<TestPushNotificationMutation>(TestPushNotificationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'testPushNotification', 'mutation');
    },
    uploadDocument(variables: UploadDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UploadDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<UploadDocumentMutation>(UploadDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uploadDocument', 'mutation');
    },
    deleteDocument(variables: DeleteDocumentMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DeleteDocumentMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<DeleteDocumentMutation>(DeleteDocumentDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteDocument', 'mutation');
    },
    globalSettings(variables?: GlobalSettingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<GlobalSettingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<GlobalSettingsQuery>(GlobalSettingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'globalSettings', 'query');
    },
    Reservation(variables?: ReservationQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ReservationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ReservationQuery>(ReservationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Reservation', 'query');
    },
    Request(variables?: RequestQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RequestQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RequestQuery>(RequestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Request', 'query');
    },
    Meeting(variables?: MeetingQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeetingQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeetingQuery>(MeetingDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Meeting', 'query');
    },
    mentors(variables?: MentorsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MentorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MentorsQuery>(MentorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'mentors', 'query');
    },
    mentor(variables: MentorQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MentorQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MentorQuery>(MentorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'mentor', 'query');
    },
    myMentor(variables?: MyMentorQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MyMentorQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MyMentorQuery>(MyMentorDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'myMentor', 'query');
    },
    stripeStatus(variables: StripeStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StripeStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StripeStatusQuery>(StripeStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stripeStatus', 'query');
    },
    order(variables: OrderQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OrderQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrderQuery>(OrderDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'order', 'query');
    },
    orderPaymentStatus(variables: OrderPaymentStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OrderPaymentStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrderPaymentStatusQuery>(OrderPaymentStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'orderPaymentStatus', 'query');
    },
    orders(variables?: OrdersQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<OrdersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<OrdersQuery>(OrdersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'orders', 'query');
    },
    Products(variables?: ProductsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ProductsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ProductsQuery>(ProductsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Products', 'query');
    },
    mentorRatings(variables: MentorRatingsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MentorRatingsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MentorRatingsQuery>(MentorRatingsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'mentorRatings', 'query');
    },
    mentorMeetingHistory(variables?: MentorMeetingHistoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MentorMeetingHistoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MentorMeetingHistoryQuery>(MentorMeetingHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'mentorMeetingHistory', 'query');
    },
    studentMeetingHistory(variables?: StudentMeetingHistoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StudentMeetingHistoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StudentMeetingHistoryQuery>(StudentMeetingHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'studentMeetingHistory', 'query');
    },
    studentOrderHistory(variables?: StudentOrderHistoryQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StudentOrderHistoryQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StudentOrderHistoryQuery>(StudentOrderHistoryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'studentOrderHistory', 'query');
    },
    Topics(variables?: TopicsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<TopicsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<TopicsQuery>(TopicsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Topics', 'query');
    },
    selectedTopics(variables?: SelectedTopicsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SelectedTopicsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SelectedTopicsQuery>(SelectedTopicsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'selectedTopics', 'query');
    },
    me(variables?: MeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<MeQuery>(MeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'me', 'query');
    },
    remainingTime(variables?: RemainingTimeQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RemainingTimeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<RemainingTimeQuery>(RemainingTimeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'remainingTime', 'query');
    },
    documents(variables?: DocumentsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<DocumentsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<DocumentsQuery>(DocumentsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'documents', 'query');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;
export function getSdkWithHooks(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  const sdk = getSdk(client, withWrapper);
  return {
    ...sdk,
    useGlobalSettings(key: SWRKeyInterface, variables?: GlobalSettingsQueryVariables, config?: SWRConfigInterface<GlobalSettingsQuery, ClientError>) {
      return useSWR<GlobalSettingsQuery, ClientError>(key, () => sdk.globalSettings(variables), config);
    },
    useReservation(key: SWRKeyInterface, variables?: ReservationQueryVariables, config?: SWRConfigInterface<ReservationQuery, ClientError>) {
      return useSWR<ReservationQuery, ClientError>(key, () => sdk.Reservation(variables), config);
    },
    useRequest(key: SWRKeyInterface, variables?: RequestQueryVariables, config?: SWRConfigInterface<RequestQuery, ClientError>) {
      return useSWR<RequestQuery, ClientError>(key, () => sdk.Request(variables), config);
    },
    useMeeting(key: SWRKeyInterface, variables?: MeetingQueryVariables, config?: SWRConfigInterface<MeetingQuery, ClientError>) {
      return useSWR<MeetingQuery, ClientError>(key, () => sdk.Meeting(variables), config);
    },
    useMentors(key: SWRKeyInterface, variables?: MentorsQueryVariables, config?: SWRConfigInterface<MentorsQuery, ClientError>) {
      return useSWR<MentorsQuery, ClientError>(key, () => sdk.mentors(variables), config);
    },
    useMentor(key: SWRKeyInterface, variables: MentorQueryVariables, config?: SWRConfigInterface<MentorQuery, ClientError>) {
      return useSWR<MentorQuery, ClientError>(key, () => sdk.mentor(variables), config);
    },
    useMyMentor(key: SWRKeyInterface, variables?: MyMentorQueryVariables, config?: SWRConfigInterface<MyMentorQuery, ClientError>) {
      return useSWR<MyMentorQuery, ClientError>(key, () => sdk.myMentor(variables), config);
    },
    useStripeStatus(key: SWRKeyInterface, variables: StripeStatusQueryVariables, config?: SWRConfigInterface<StripeStatusQuery, ClientError>) {
      return useSWR<StripeStatusQuery, ClientError>(key, () => sdk.stripeStatus(variables), config);
    },
    useOrder(key: SWRKeyInterface, variables: OrderQueryVariables, config?: SWRConfigInterface<OrderQuery, ClientError>) {
      return useSWR<OrderQuery, ClientError>(key, () => sdk.order(variables), config);
    },
    useOrderPaymentStatus(key: SWRKeyInterface, variables: OrderPaymentStatusQueryVariables, config?: SWRConfigInterface<OrderPaymentStatusQuery, ClientError>) {
      return useSWR<OrderPaymentStatusQuery, ClientError>(key, () => sdk.orderPaymentStatus(variables), config);
    },
    useOrders(key: SWRKeyInterface, variables?: OrdersQueryVariables, config?: SWRConfigInterface<OrdersQuery, ClientError>) {
      return useSWR<OrdersQuery, ClientError>(key, () => sdk.orders(variables), config);
    },
    useProducts(key: SWRKeyInterface, variables?: ProductsQueryVariables, config?: SWRConfigInterface<ProductsQuery, ClientError>) {
      return useSWR<ProductsQuery, ClientError>(key, () => sdk.Products(variables), config);
    },
    useMentorRatings(key: SWRKeyInterface, variables: MentorRatingsQueryVariables, config?: SWRConfigInterface<MentorRatingsQuery, ClientError>) {
      return useSWR<MentorRatingsQuery, ClientError>(key, () => sdk.mentorRatings(variables), config);
    },
    useMentorMeetingHistory(key: SWRKeyInterface, variables?: MentorMeetingHistoryQueryVariables, config?: SWRConfigInterface<MentorMeetingHistoryQuery, ClientError>) {
      return useSWR<MentorMeetingHistoryQuery, ClientError>(key, () => sdk.mentorMeetingHistory(variables), config);
    },
    useStudentMeetingHistory(key: SWRKeyInterface, variables?: StudentMeetingHistoryQueryVariables, config?: SWRConfigInterface<StudentMeetingHistoryQuery, ClientError>) {
      return useSWR<StudentMeetingHistoryQuery, ClientError>(key, () => sdk.studentMeetingHistory(variables), config);
    },
    useStudentOrderHistory(key: SWRKeyInterface, variables?: StudentOrderHistoryQueryVariables, config?: SWRConfigInterface<StudentOrderHistoryQuery, ClientError>) {
      return useSWR<StudentOrderHistoryQuery, ClientError>(key, () => sdk.studentOrderHistory(variables), config);
    },
    useTopics(key: SWRKeyInterface, variables?: TopicsQueryVariables, config?: SWRConfigInterface<TopicsQuery, ClientError>) {
      return useSWR<TopicsQuery, ClientError>(key, () => sdk.Topics(variables), config);
    },
    useSelectedTopics(key: SWRKeyInterface, variables?: SelectedTopicsQueryVariables, config?: SWRConfigInterface<SelectedTopicsQuery, ClientError>) {
      return useSWR<SelectedTopicsQuery, ClientError>(key, () => sdk.selectedTopics(variables), config);
    },
    useMe(key: SWRKeyInterface, variables?: MeQueryVariables, config?: SWRConfigInterface<MeQuery, ClientError>) {
      return useSWR<MeQuery, ClientError>(key, () => sdk.me(variables), config);
    },
    useRemainingTime(key: SWRKeyInterface, variables?: RemainingTimeQueryVariables, config?: SWRConfigInterface<RemainingTimeQuery, ClientError>) {
      return useSWR<RemainingTimeQuery, ClientError>(key, () => sdk.remainingTime(variables), config);
    },
    useDocuments(key: SWRKeyInterface, variables?: DocumentsQueryVariables, config?: SWRConfigInterface<DocumentsQuery, ClientError>) {
      return useSWR<DocumentsQuery, ClientError>(key, () => sdk.documents(variables), config);
    }
  };
}
export type SdkWithHooks = ReturnType<typeof getSdkWithHooks>;