import React, { useContext, useEffect, useState } from 'react'
import tw from '../../../lib/tailwind'
import { ActivityIndicator, Platform, Text, TouchableOpacity, View } from 'react-native'

import AuthContext, { AuthContextValue } from '../../contexts/auth'
import * as Linking from 'expo-linking'
import { EventType } from 'expo-linking'

import { InitiatePaymentResponse, PaymentStatusValue } from '../../generated/graphql'
import useRemainingTime from '../../hooks/useRemainingTime'
import ActionButton from '../ActionButton'

interface Props {
  toggleOpen: () => void
}

enum PaymentState {
  IDLE,
  INIT,
  VIPPS,
  PENDING,
  SUCCESS,
  ERROR,
}

const Refill = ({ toggleOpen }: Props) => {
  const [state, setState] = useState<PaymentState>(PaymentState.IDLE)

  const [amount, setAmount] = useState(1)
  const [orderId, setOrderId] = useState<string | null>(null)
  const [isOrdering, setIsOrdering] = useState(false)
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const { data } = getSdk().useProducts('products')
  const { mutate: refreshRemainingTime } = useRemainingTime()

  const resetOrderState = () => {
    setState(PaymentState.IDLE)
    setOrderId(null)
    setAmount(1)
    setIsOrdering(false)
  }
  // TODO: Handle exceptions
  const { data: orderData } = getSdk().useOrderPaymentStatus(
    'currentOrder',
    {
      id: orderId || '',
    },
    {
      isPaused: () => state !== PaymentState.PENDING && !!orderId,
      refreshInterval: state === PaymentState.PENDING ? 500 : 0,
    }
  )

  useEffect(() => {
    console.log({ orderPaymentStatus: orderData?.orderPaymentStatus })
    if (!orderData?.orderPaymentStatus) {
      return
    }

    if (orderData.orderPaymentStatus === PaymentStatusValue.Cancelled) {
      setState(PaymentState.INIT)
      setIsOrdering(false)
      void refreshRemainingTime()
    }
    if (state !== PaymentState.IDLE && orderData.orderPaymentStatus === PaymentStatusValue.Paid) {
      setState(PaymentState.SUCCESS)
      void refreshRemainingTime()
    }
  }, [orderData?.orderPaymentStatus])

  const initiatePaymentRequest = async () => {
    setIsOrdering(true)
    try {
      if (!data?.products) {
        setIsOrdering(false)
        return
      }

      setState(PaymentState.INIT)

      const { initiatePayment }: { initiatePayment: InitiatePaymentResponse } =
        await getSdk().initiatePayment({
          productId: data.products[0].id,
          amount: amount,
          platform: Platform.OS === 'web' ? 'web' : 'app',
        })

      if (!initiatePayment) {
        setIsOrdering(false)
        throw new Error('We did not receive a payment url')
      }
      const redirectEventHandler = async ({ url }: EventType) => {
        const parsed = Linking.parse(url)
        // TODO: Check for error status on parsed
        setState(PaymentState.PENDING)
        Linking.removeEventListener('url', redirectEventHandler)
      }

      Linking.addEventListener('url', redirectEventHandler)

      if (!initiatePayment?.orderId) {
        setIsOrdering(false)
        throw new Error('Did not receive order id')
      }
      setOrderId(initiatePayment.orderId)

      setState(PaymentState.VIPPS)

      await Linking.openURL(initiatePayment.url)
    } catch (error) {
      setIsOrdering(false)
      console.error(error)
      throw new Error("Couldn't initiate payment")
    }
  }

  const secondsToMinutesPretty = (seconds: number) => {
    const minutes = (seconds / 60) | 0
    return `${minutes}`
  }

  return (
    <View
      style={tw`absolute z-10 flex-1 w-full px-3 py-8 rounded-t-2xl bottom-0 shadow-lg left-0 bg-white `}
    >
      {state === PaymentState.PENDING ? (
        <>
          <ActivityIndicator size={'large'} />
          <Text style={tw`text-center p-1`}>Fullfører bestilling</Text>
        </>
      ) : state === PaymentState.SUCCESS ? (
        <>
          <Text style={tw`font-bold pb-4 text-xl text-center`}>Takk for ditt kjøp!</Text>
          <TouchableOpacity
            style={tw`bg-[#FC5B24] text-center p-4 rounded-2xl`}
            onPress={() => {
              resetOrderState()
              toggleOpen()
            }}
          >
            <Text style={tw`text-lg text-white font-bold text-center`}>Lukk</Text>
          </TouchableOpacity>
        </>
      ) : !data ? (
        <>
          <ActivityIndicator size={'large'} />
          <Text style={tw`text-center p-1`}>Laster</Text>
        </>
      ) : (
        <>
          <Text style={tw`text-xl font-bold`}>Minutter</Text>
          <View style={tw`flex-row justify-between items-center`}>
            <View style={tw`flex-row items-center my-5`}>
              <TouchableOpacity
                style={tw`h-12 w-12 items-center justify-center rounded-full border-grey-light border mr-3`}
                onPress={() => setAmount(amount <= 1 ? 1 : amount - 1)}
              >
                <Text style={tw`text-2xl font-bold text-grey `}>-</Text>
              </TouchableOpacity>
              <Text style={tw`text-2xl font-bold mr-3`}>
                {secondsToMinutesPretty((data?.products?.[0]?.durationInSeconds ?? 0) * amount)}
              </Text>
              <TouchableOpacity
                style={tw`h-12 w-12 items-center justify-center rounded-full border-grey-light border bg-blue`}
                onPress={() => setAmount(amount + 1)}
              >
                <Text style={tw`text-2xl font-bold text-white`}>+</Text>
              </TouchableOpacity>
            </View>
            <TouchableOpacity style={tw`px-5 py-2 rounded-full border border-grey-light`}>
              <Text style={tw`font-bold text-lg`}>
                Totalt {((data.products?.[0]?.priceNok ?? 0) / 100) * amount} kr
              </Text>
            </TouchableOpacity>
          </View>

          <ActionButton
            isLoading={isOrdering}
            label={isOrdering ? 'Betaler' : 'Betal'}
            color={'orange'}
            onPress={initiatePaymentRequest}
          />
          <ActionButton label={'Avbryt'} color={'grey'} onPress={toggleOpen} />
        </>
      )}
    </View>
  )
}
export default Refill
