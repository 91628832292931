import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import RegisterScreen from '../screens/Register'

const RegisterNavigator = () => {
  const Register = createNativeStackNavigator()

  return (
    <Register.Navigator initialRouteName="Register">
      <Register.Screen
        name="Register"
        component={RegisterScreen}
        options={{ headerShown: false }}
      />
    </Register.Navigator>
  )
}

export default RegisterNavigator
