import * as WebBrowser from 'expo-web-browser'
import * as Linking from 'expo-linking'
import { EventType } from 'expo-linking'
import Constants from 'expo-constants'
import { useContext, useEffect, useState } from 'react'
import { Alert, Platform } from 'react-native'
import AuthContext, { AuthContextValue } from '../contexts/auth'
import AsyncStorage from '@react-native-async-storage/async-storage'

const { API_URL, VIPPS_REDIRECT_URL } = Constants.manifest?.extra!

const LOGIN_ENDPOINT = `${API_URL}/api/vipps`
const CALLBACK_ENDPOINT = `${API_URL}/api/vipps-callback`

const fetchToken = (code: string, state: string, scope: string) => {
  return fetch(
    `${CALLBACK_ENDPOINT}?code=${code}&scope=${scope}&state=${state}${
      Platform.OS === 'web' ? '&type=web' : ''
    }`
  ).then((res) => res.json())
}

/**
 * Stores a jwt received from a successful login in appropriate storage
 */
const storeToken = async (token: string) => {
  await AsyncStorage.setItem('jwt', token)
}

const handleError = (error: string, message: string) => {
  throw new Error(`${error} ${message}`)
}

export default function useVippsLogin() {
  const [isLoading, setIsLoading] = useState(false)
  const [fetchTokenIsLoading, setFetchTokenIsLoading] = useState(false)
  const { handleAuthErrors, setAuthError } = useContext<AuthContextValue>(AuthContext)

  // Hook to receive current url.
  const url = Linking.useURL()

  useEffect(() => {
    // Check the URL. If it contains a code then it means we have been redirected from vipps login.
    if (!url) {
      return
    }

    if (fetchTokenIsLoading) {
      return
    }

    if (Platform.OS === 'web') {
      if (!url) {
        return
      }
      setFetchTokenIsLoading(true)

      const { queryParams } = Linking.parse(url)

      if (!queryParams) {
        return
      }

      const { code, state, scope } = queryParams

      if (code && state && scope) {
        // Send in the code.
        fetchToken(code as string, state as string, scope as string)
          .then((tokens) => {
            // Remove code from url
            // Linking.openURL(Linking.createURL(''))
            console.log(`Received some tokens ${JSON.stringify(tokens)}`)
            storeToken(tokens.jwt)
          })
          .catch((err) => {
            console.log('Error when fetching tokens with code')

            console.log(err)
          })
          .finally(() => {
            setFetchTokenIsLoading(false)
            // This removes code and other query params from url, and refreshes browser on successful login
            Linking.openURL(Linking.createURL(''))
          })
      }
    }
  }, [url])

  const login = (): Promise<string> => {
    setIsLoading(true)
    setAuthError(null)

    return new Promise(async (resolve: any, reject: any): Promise<void> => {
      try {
        const isWeb = Platform.OS === 'web'
        const queryString = isWeb ? '?type=web' : ''
        // Initiate by getting the authorization uri
        const res = await fetch(LOGIN_ENDPOINT + queryString).then((res) => res.json())
        const { authorizationUrl, error } = res

        if (error) {
          console.log(error)
          return
        }

        if (isWeb) {
          // Redirect to the authorization url.
          const canOpen = await Linking.canOpenURL(authorizationUrl)

          if (canOpen) {
            Linking.openURL(authorizationUrl)
          } else {
            throw Error('Unable to open authorization url')
          }
        } else {
          // Open the uri in browser
          const redirectUrl = Linking.createURL('callback')

          let authSessionResult = await WebBrowser.openAuthSessionAsync(
            authorizationUrl,
            redirectUrl,
            {
              createTask: true,
              showInRecents: true,
            }
          )

          // Two known scenarios from here:
          // - The result from openAuthSessionAsync gives us a code (seems to happen on ios)
          // - The result from openAuthSessionAsync gives us a resume_uri (seems to be the case on android)

          console.log(JSON.stringify(authSessionResult))

          if (authSessionResult.type !== 'success') {
            reject('Bad browser result type')
            throw new Error(`Bad browser result type ${authSessionResult.type}`)
          }

          const { queryParams } = Linking.parse(authSessionResult.url)
          const { resume_uri, code, scope, state, error, error_description }: any = queryParams

          if (error) {
            handleError(error, error_description)
            reject(error)
          }

          if (code) {
            const tokens = await fetchToken(code, state, scope)
            await storeToken(tokens.jwt)
            resolve(tokens.jwt)
          } else if (resume_uri) {
            // Open the browser again using the resume_uri. We cannot use openAuthSessionAsync for this.
            // Setup a linking listener for when vipps redirects us back.

            const redirectEventHandler = async ({ url }: EventType) => {
              const { queryParams: resumeQueryParams } = Linking.parse(url)
              const { code, scope, state, error, error_description }: any = resumeQueryParams

              if (error) {
                handleError(error, error_description)
              }

              const tokens = await fetchToken(code, state, scope)

              await storeToken(tokens.jwt)
              // Remove the listener. We dont need it anymore. :wave:
              listener.remove()
              resolve(tokens.jwt)
            }

            const listener = Linking.addEventListener('url', redirectEventHandler)

            await WebBrowser.openBrowserAsync(resume_uri)
          }
        }
      } catch (error) {
        setIsLoading(false)
        handleAuthErrors(error, true)
      }
    })
  }

  return {
    login,
    isLoading,
  }
}
