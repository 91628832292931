import { LayoutAnimation, Linking, ScrollView, Text, TouchableOpacity, View } from 'react-native'
import tw from '../../../lib/tailwind'
import React, { useEffect, useState } from 'react'
import { FontAwesome, MaterialIcons } from '@expo/vector-icons'
import Refill from '../../components/Refill'
import { useContext } from 'react'
import AuthContext from '../../contexts/auth'
import useRemainingTime from '../../hooks/useRemainingTime'
import { SALES_TERMS_URL, SUPPORT_URL } from '../../utils/constants'
import { useLogout } from '../../hooks/useLogout'
import ActionButton from '../../components/ActionButton'
import { Link } from '@react-navigation/native'

const SettingsScreen = ({ navigation }: any) => {
  const [open, setOpen] = useState(false)
  const { hours, minutes } = useRemainingTime()
  const toggleOpen = () => {
    setOpen(!open)
    LayoutAnimation.configureNext(LayoutAnimation.create(150, 'easeInEaseOut', 'opacity'))
  }

  const { logout } = useLogout()

  return (
    <>
      <ScrollView style={tw`flex-1 bg-white`}>
        <View style={tw`flex-1`}>
          <View
            style={tw`items-center justify-center flex-1 py-5 shadow-md bg-white mx-3 my-5 rounded-xl`}
          >
            <Text style={tw`text-sm font-bold text-center`}>Du har</Text>
            <Text style={tw`text-4xl font-bold text-center my-2`}>
              {hours}:{minutes}
            </Text>
            <Text style={tw`text-sm font-bold text-center`}>Undervisningstimer</Text>
          </View>
          {!open && (
            <View style={tw`flex-1 mx-3`}>
              <ActionButton label={'Fyll på'} onPress={toggleOpen} color={'orange'} />
              <Text
                style={tw`text-grey text-sm py-2 text-center`}
                onPress={() => Linking.openURL(SALES_TERMS_URL)}
              >
                Betalingsvilkår
              </Text>
            </View>
          )}
          <View style={tw`mx-3 my-5 flex-1`}>
            <TouchableOpacity
              style={tw`justify-between items-center flex-row py-4`}
              onPress={() => navigation.navigate('MeetingHistory')}
            >
              <View style={tw`flex-row items-center`}>
                <MaterialIcons name="history" size={30} color="black" />
                <Text style={tw`ml-3 text-black text-lg`}>Møtehistorikk</Text>
              </View>
              <FontAwesome name="angle-right" size={24} />
            </TouchableOpacity>
            <TouchableOpacity
              style={tw`justify-between items-center flex-row py-4 border-b border-grey-light`}
              onPress={() => navigation.navigate('OrderHistory')}
            >
              <View style={tw`flex-row items-center`}>
                <MaterialIcons name="history" size={30} color="black" />
                <Text style={tw`ml-3 text-black text-lg`}>Ordrehistorikk</Text>
              </View>
              <FontAwesome name="angle-right" size={24} />
            </TouchableOpacity>
            <TouchableOpacity
              style={tw`justify-between items-center flex-row py-4`}
              onPress={() => Linking.openURL(SUPPORT_URL)}
            >
              <View style={tw`flex-row items-center`}>
                <MaterialIcons name="support" size={30} color="black" />
                <Text style={tw`ml-3 text-black text-lg`}>Hjelp og kundeservice</Text>
              </View>
              <FontAwesome name="angle-right" size={24} />
            </TouchableOpacity>
            <TouchableOpacity
              style={tw`justify-between items-center flex-row py-4`}
              onPress={() => navigation.navigate('AppAboutStudent')}
            >
              <View style={tw`flex-row items-center`}>
                <MaterialIcons name="info-outline" size={30} color="black" />
                <Text style={tw`ml-3 text-black text-lg`}>Om</Text>
              </View>
              <FontAwesome name="angle-right" size={24} />
            </TouchableOpacity>
            <TouchableOpacity
              style={tw`justify-between items-center flex-row py-4`}
              onPress={logout}
            >
              <View style={tw`flex-row items-center`}>
                <MaterialIcons name="logout" size={30} color="black" />
                <Text style={tw`ml-3 text-black text-lg`}>Logg ut</Text>
              </View>
              <FontAwesome name="angle-right" size={24} />
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      {open && <Refill toggleOpen={toggleOpen} />}
    </>
  )
}

export default SettingsScreen
