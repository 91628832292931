import React from 'react'
import { SafeAreaView, ScrollView, Text, TouchableOpacity, View, StatusBar } from 'react-native'
import tw from '../../../lib/tailwind'
import { FontAwesome, MaterialIcons } from '@expo/vector-icons'
import UserIcon from '../../components/Icons/user'
import ToggleAvailability from '../../components/ToggleAvailability'
import ImageUpload from '../../components/ImageUpload'
import useMentorProfile from '../../hooks/useMentorProfile'
import { useLogout } from '../../hooks/useLogout'
import MentorStats from '../../components/MentorStats'
import useGlobalSettings from '../../hooks/useGlobalSettings'

const MentorScreen = ({ navigation }: any) => {
  const { logout } = useLogout()
  const { mentorProfile } = useMentorProfile()

  return (
    <SafeAreaView style={tw.style(`flex-1 bg-white`, { paddingTop: StatusBar.currentHeight })}>
      <ToggleAvailability />
      <ScrollView>
        <ImageUpload avatarUrl={mentorProfile?.avatarUrl} />

        <MentorStats
          moneyEarned={mentorProfile?.moneyEarned ?? 0}
          meetingsCount={mentorProfile?.meetingsCount ?? 0}
        />

        <View style={tw`mx-3 my-5 flex-1`}>
          <TouchableOpacity
            style={tw`justify-between items-center flex-row py-4`}
            onPress={() => navigation.navigate('AboutMentor')}
          >
            <View style={tw`flex-row items-center`}>
              <UserIcon />
              <Text style={tw`ml-3 text-black text-lg`}>Personlig info</Text>
            </View>
            <FontAwesome name="angle-right" size={24} />
          </TouchableOpacity>
          <TouchableOpacity
            style={tw`justify-between items-center flex-row py-4`}
            onPress={() => navigation.navigate('MentorSelectTopics')}
          >
            <View style={tw`flex-row items-center`}>
              <FontAwesome name="book" size={26} />
              <Text style={tw`ml-3 text-black text-lg`}>Mine fag</Text>
            </View>
            <FontAwesome name="angle-right" size={24} />
          </TouchableOpacity>

          <TouchableOpacity
            style={tw`justify-between items-center flex-row py-4`}
            onPress={() => navigation.navigate('DocumentsMentor')}
          >
            <View style={tw`flex-row items-center`}>
              <FontAwesome name="file" size={26} />
              <Text style={tw`ml-3 text-black text-lg`}>Mine dokumenter</Text>
            </View>
            <FontAwesome name="angle-right" size={24} />
          </TouchableOpacity>

          <TouchableOpacity
            style={tw`justify-between items-center flex-row py-4`}
            onPress={() => navigation.navigate('PayoutMentor')}
          >
            <View style={tw`flex-row items-center`}>
              <FontAwesome name="credit-card" size={24} />
              <Text style={tw`ml-3 text-black text-lg`}>Utbetaling</Text>
            </View>
            <FontAwesome name="angle-right" size={24} />
          </TouchableOpacity>

          <TouchableOpacity
            style={tw`justify-between items-center flex-row py-4`}
            onPress={() => navigation.navigate('HistoryMentor')}
          >
            <View style={tw`flex-row items-center`}>
              <MaterialIcons name="history" size={30} color="black" />
              <Text style={tw`ml-3 text-black text-lg`}>Møtehistorikk</Text>
            </View>
            <FontAwesome name="angle-right" size={24} />
          </TouchableOpacity>
          <TouchableOpacity
            style={tw`justify-between items-center flex-row py-4`}
            onPress={() => navigation.navigate('ReviewsMentor')}
          >
            <View style={tw`flex-row items-center`}>
              <MaterialIcons name="stars" size={30} color="black" />
              <Text style={tw`ml-3 text-black text-lg`}>Anmeldelser</Text>
            </View>
            <FontAwesome name="angle-right" size={24} />
          </TouchableOpacity>
          <TouchableOpacity
            style={tw`justify-between items-center flex-row py-4`}
            onPress={() => navigation.navigate('AppAboutMentor')}
          >
            <View style={tw`flex-row items-center`}>
              <MaterialIcons name="info-outline" size={30} color="black" />
              <Text style={tw`ml-3 text-black text-lg`}>Om Min Mentor</Text>
            </View>
            <FontAwesome name="angle-right" size={24} />
          </TouchableOpacity>
          <TouchableOpacity style={tw`justify-between items-center flex-row py-4`} onPress={logout}>
            <View style={tw`flex-row items-center`}>
              <MaterialIcons name="logout" size={30} color="black" />
              <Text style={tw`ml-3 text-black text-lg`}>Logg ut</Text>
            </View>
            <FontAwesome name="angle-right" size={24} />
          </TouchableOpacity>
        </View>
      </ScrollView>
    </SafeAreaView>
  )
}

export default MentorScreen
