import { useEffect, useState } from 'react'
import { intervalToDuration, Duration } from 'date-fns'

interface Props {
  startTime?: Date
}

export const useTimer = ({ startTime = new Date() }: Props) => {
  const [time, setTime] = useState<Duration>({
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  })

  const [totalMillis, setTotalMillis] = useState<number>(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(
        intervalToDuration({
          start: startTime || new Date(),
          end: new Date(),
        })
      )

      setTotalMillis(Date.now() - startTime.getTime())
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [startTime])

  return { ...time, totalMillis }
}
