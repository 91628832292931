import { intervalToDuration } from 'date-fns'
import { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { zeroPad } from 'react-countdown'
import AuthContext, { AuthContextValue } from '../contexts/auth'

// Returns a users remaining time
export default function useRemainingTime() {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)

  const { data, error, mutate } = getSdk().useRemainingTime(
    'remainingTime',
    {},
    { refreshInterval: 1000 }
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalHours, setTotalHours] = useState<number>(0)
  const [totalMinutes, setTotalMinutes] = useState<number>(0)
  const [totalSeconds, setTotalSeconds] = useState<number>(0)
  const [totalMillis, setTotalMillis] = useState<number>(0)

  useEffect(() => {
    const loading = !data
    setIsLoading(loading)

    if (loading || error) {
      return
    }

    console.log(data)

    const remainingHours = Math.floor(data.remainingTime / 3600)
    const remainingMinutes = Math.floor(data.remainingTime / 60)
    const remainingSeconds = Math.floor(data.remainingTime)

    setTotalHours(remainingHours)
    setTotalMinutes(remainingMinutes)
    setTotalSeconds(remainingSeconds)
    setTotalMillis(remainingSeconds * 1000)
  }, [data])

  const hours = zeroPad(totalHours % 12)
  const minutes = zeroPad(totalMinutes % 60)
  const seconds = zeroPad(totalSeconds % 60)

  return {
    isLoading,
    error,
    totalHours,
    totalMinutes,
    totalSeconds,
    totalMillis,
    hours,
    minutes,
    seconds,
    mutate,
  }
}
