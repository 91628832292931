import { ActivityIndicator, FlatList, Text, View } from 'react-native'
import React, { useContext } from 'react'
import tw from '../../../lib/tailwind'
import StarRating from '../StarRating'
import AuthContext from '../../contexts/auth'
import { formatDistance } from 'date-fns'
import nbLocale from 'date-fns/locale/nb'

interface Props {
  userId: string
}

export const MentorReviews = ({ userId }: Props) => {
  const { getSdk } = useContext(AuthContext)

  const { data: ratings, isValidating } = getSdk().useMentorRatings('mentorRating', {
    userId,
  })

  const renderItem = ({ item }: any) => (
    <View style={tw`py-3`}>
      <View style={tw`flex-row justify-between items-center`}>
        <Text style={tw`text-lg font-bold text-black pb-2`}>{item.creator.firstName}</Text>
        <Text style={tw`text-xs text-grey`}>
          {formatDistance(new Date(), new Date(item.createdAt), {
            locale: nbLocale,
          })}
        </Text>
      </View>
      <View>
        <StarRating rating={item.rating} />
        <Text style={tw`text-sm text-grey pt-2`}>{item.message}</Text>
      </View>
    </View>
  )

  if (isValidating) return <ActivityIndicator size={'large'} style={tw`py-6`} />
  if (!ratings?.mentorRatings?.length)
    return <Text style={tw`py-6 text-center`}>Ingen anmeldelser enda...</Text>
  return <FlatList data={ratings.mentorRatings} renderItem={renderItem} />
}
