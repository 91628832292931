import React from 'react'
import { SafeAreaView, Text } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import tw from '../../../lib/tailwind'
import StripeAccount from '../../components/StripeAccount'

const AboutScreen = () => {
  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <KeyboardAwareScrollView style={tw`flex-1`}>
        <StripeAccount />
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )
}

export default AboutScreen
