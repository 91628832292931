import { StripeStatusResponse } from '../../generated/graphql'
import { Linking, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import StripeAccountProgress from './progress'
import ActionButton from '../ActionButton'
import React from 'react'

const StripeOnboarding = ({ data }: { data?: StripeStatusResponse }) => {
  return (
    <View style={tw`m-3 mb-1`}>
      <Text style={tw`m-3 mb-8`}>
        Vi er snart i mål! Nå trenger vi litt ekstra informasjon om deg og hvordan vi skal utbetale
        dine opparbeidete timer.
      </Text>
      <StripeAccountProgress status={'ONBOARDING'} />

      <ActionButton
        label={'Fyll ut betalingsinformasjon'}
        color={'blue'}
        onPress={() => data?.onBoardingUrl && Linking.openURL(data.onBoardingUrl)}
      />
      <Text style={tw`text-center p-2`}>Du vil bli sendt tilbake til appen når du er ferdig.</Text>
    </View>
  )
}

export default StripeOnboarding
