import React from 'react'
import { MentorReviews } from '../../components/Reviews'
import { SafeAreaView, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import StarRating from '../../components/StarRating'
import useMe from '../../hooks/useMe'

const ReviewsScreen = () => {
  const { me } = useMe()

  return (
    <SafeAreaView style={tw`p-5 flex-1 bg-white`}>
      <View style={tw`mx-3`}>
        {me && (
          <>
            <View style={tw` items-center mt-6`}>
              <StarRating
                rating={me?.mentorProfile?.averageRating}
                reviewCount={me?.mentorProfile?.ratingsCount}
              />
              <Text style={tw`font-bold text-xl mt-3 text-grey`}>
                Gjennomsnitt av {me?.mentorProfile?.ratingsCount}
              </Text>
            </View>
            <MentorReviews userId={me?.id} />
          </>
        )}
      </View>
    </SafeAreaView>
  )
}

export default ReviewsScreen
