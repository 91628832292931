import { ActivityIndicator, Text, TouchableOpacity } from 'react-native'
import tw from '../../lib/tailwind'

interface ActionButtonProps {
  isLoading?: boolean
  label: string
  onPress?: () => void
  color?: 'blue' | 'orange' | 'grey' | 'red'
  disabled?: boolean
}

const ActionButton = ({
  isLoading = false,
  label,
  onPress,
  color = 'blue',
  disabled = false,
}: ActionButtonProps) => {
  return (
    <TouchableOpacity
      style={tw.style(
        `bg-${color} text-center p-4 rounded-2xl flex-row text-center w-full my-1 justify-center ${
          isLoading ? 'opacity-80' : ''
        } ${disabled ? 'opacity-50' : ''}`
      )}
      onPress={() => !isLoading && onPress && onPress()}
      disabled={disabled || isLoading}
    >
      <Text style={tw`text-lg text-white font-bold text-center`}>{label}</Text>
      {isLoading && <ActivityIndicator color="white" style={tw`ml-4`} />}
    </TouchableOpacity>
  )
}

export default ActionButton
