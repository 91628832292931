module.exports = {
  theme: {
    colors: {
      grey: {
        DEFAULT: '#8E8E8E',
        light: '#F0F0F0',
        dark: '#676767',
      },
      white: '#FFFFFF',
      black: '#3B3B3B',
      orange: '#FC5B24',
      red: '#FF2F2F',
      blue: {
        DEFAULT: '#0679DD',
        light: '#6EBBFF',
      },
      red: {
        DEFAULT: 'red',
      },
      transparent: 'transparent',
    },
  },
  plugins: [],
}
