import { ActivityIndicator, Alert, FlatList, Platform, Text, TouchableOpacity, View } from 'react-native'
import tw from '../../../lib/tailwind'
import FileIcon from '../Icons/file'
import * as DocumentPicker from 'expo-document-picker'
import * as FileSystem from 'expo-file-system'
import { useContext, useState } from 'react'
import AuthContext, { AuthContextValue } from '../../contexts/auth'

const DocumentUploader = () => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)

  const [deleteIsLoading, setDeleteIsLoading] = useState(false)
  const [uploadIsLoading, setUploadIsLoading] = useState(false)
  const [deletingId, setDeletingId] = useState<string | null>(null)
  const { data, mutate, isValidating } = getSdk().useDocuments('documents')

  const toBase64String = (data: string, mimeType: string = '') => {
    return `data:${mimeType};base64,${data}`
  }

  const onUploadClick = async () => {
    try {
      setUploadIsLoading(true)
      const result = await DocumentPicker.getDocumentAsync({
        type: ['application/pdf', 'application/zip', 'image/*', 'application/*'],
      })

      if (result && result?.type !== 'success') {
        // User did not pick a document.
        return
      }

      if (result.type === 'success' && result?.size && result?.size > 10485760) {
        Alert.alert('Feil', 'Filen du valgte er for stor')
        return
      }

      if(Platform.OS === 'web') {
          function getBase64(file: File) {

          return new Promise<string>((resolve)=> {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
              resolve(reader.result as string)
            };
            reader.onerror = function (error) {
              console.log('Error: ', error);
            };
          })
        }
        // do web stuff



       if(!result.file) throw new Error('No file found')

       const base64= await getBase64(result.file); // prints the base64 string
        const response = await getSdk().uploadDocument({ file: base64, fileName: result.name })
      }else {
        const base64Content = await FileSystem.readAsStringAsync(result.uri, {
          encoding: FileSystem.EncodingType.Base64,
        })

        const base64 = toBase64String(base64Content, result.mimeType)
        const response = await getSdk().uploadDocument({ file: base64, fileName: result.name })
      }

      await mutate()
    } catch (error) {
      console.log(error)
    } finally {
      setUploadIsLoading(false)
    }
  }

  const onDeletePress = async (id: string) => {
    try {
      setDeleteIsLoading(true)
      setDeletingId(id)
      await getSdk().deleteDocument({ id })
      await mutate()
    } catch (error: any) {
      if (error?.response?.errors[0]) {
        Alert.alert('Error', error?.response?.errors[0].message)
      } else {
        Alert.alert('Error', error ? JSON.stringify(error) : 'Could not delete document')
      }
      throw error
    } finally {
      setDeleteIsLoading(false)
      setDeletingId(null)
    }
  }
  return (
    <View style={tw`justify-start`}>
      {isValidating ? (
        <ActivityIndicator color="blue" style={tw`my-5`} />
      ) : data?.documents ? (
        <FlatList
          initialNumToRender={20}
          data={data.documents}
          keyExtractor={(item, i) => item.id + i}
          renderItem={({ item }) => (
            <View
              style={tw`flex-row flex-1 justify-between items-center mt-4 first:mt-0 bg-[#F8FCFF] rounded-md p-3`}
            >
              <Text style={tw`flex-wrap flex-row shrink-1`}>{String(item.originalFilename)}</Text>
              <TouchableOpacity
                style={tw`p-2 shrink-0 ml-3`}
                onPress={() => onDeletePress(item.id)}
                disabled={deleteIsLoading}
              >
                {deleteIsLoading && deletingId === item.id ? (
                  <ActivityIndicator color="blue" />
                ) : (
                  <Text style={tw`text-blue`}>Fjern</Text>
                )}
              </TouchableOpacity>
            </View>
          )}
        />
      ) : (
        <Text style={tw`text-center p-4`}>Ingen dokumenter er lastet opp enda</Text>
      )}

      <TouchableOpacity
        style={tw`p-5 mt-2 flex-row border-blue rounded-lg border items-center justify-between bg-[#F8FCFF]`}
        onPress={onUploadClick}
        disabled={uploadIsLoading}
      >
        <View style={tw`flex-row items-center`}>
          <FileIcon />
          <Text style={tw`text-lg text-grey-dark ml-6`}>
            Last opp{!!data?.documents?.length && ' flere'}
          </Text>
        </View>
        {uploadIsLoading && <ActivityIndicator color="blue" />}
      </TouchableOpacity>
      <Text style={tw`text-center p-4`}>Dokumenter må lastes opp som PDF</Text>
    </View>
  )
}

export default DocumentUploader
