import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import MentorScreen from '../../screens/Mentor'
import AboutScreen from '../../screens/About'
import DocumentScreen from '../../screens/Documents'
import PayoutScreen from '../../screens/Payout'
import HistoryScreen from '../../screens/Settings/accounthistory'
import ReviewsScreen from '../../screens/Reviews'
import MeetingRoomScreen from '../../screens/Room'
import ReceivedRequestScreen from '../../screens/Room/receivedRequest'
import AddReview from '../../screens/Reviews/add'
import DeclineScreen from '../../screens/Room/decline'
import MentorSelectTopics from '../../screens/Mentor/selectTopics'
import AppAboutMentor from '../../screens/Settings/appAboutMentor'

import useMeeting from '../../hooks/useMeeting'

const MentorNavigator = () => {
  const { data } = useMeeting()
  const Mentor = createNativeStackNavigator()

  if (data?.meeting)
    return (
      <Mentor.Navigator>
        {data?.meeting?.endedAt ? (
          <Mentor.Screen
            name="MentorAddReview"
            component={AddReview}
            options={{
              headerShown: false,
            }}
          />
        ) : (
          <Mentor.Screen
            name="MentorRoom"
            component={MeetingRoomScreen}
            options={{
              headerShown: false,
            }}
          />
        )}
      </Mentor.Navigator>
    )
  else if (data?.request)
    return (
      <Mentor.Navigator initialRouteName="ReceivedRequest">
        <Mentor.Screen
          name="ReceivedRequest"
          component={ReceivedRequestScreen}
          options={{
            headerShown: false,
          }}
        />
        <Mentor.Screen
          name="DeclineRequest"
          component={DeclineScreen}
          options={{
            headerShown: false,
          }}
        />
      </Mentor.Navigator>
    )
  return (
    <Mentor.Navigator initialRouteName="Mentor">
      <Mentor.Screen
        name="Mentor"
        component={MentorScreen}
        options={{
          headerShown: false,
        }}
      />
      <Mentor.Screen
        name="MentorSelectTopics"
        component={MentorSelectTopics}
        options={{
          headerShown: true,
          headerTitle: 'Mine fag',
          headerBackTitleVisible: true,
          headerBackTitle: 'Tilbake',
        }}
      />
      <Mentor.Screen
        name="AboutMentor"
        component={AboutScreen}
        options={{
          headerShown: true,
          headerTitle: 'Om meg',
          headerBackTitleVisible: true,
          headerBackTitle: 'Tilbake',
        }}
      />
      <Mentor.Screen
        name="DocumentsMentor"
        component={DocumentScreen}
        options={{
          headerShown: true,
          headerTitle: 'Mine dokumenter',
          headerBackTitleVisible: true,
          headerBackTitle: 'Tilbake',
        }}
      />
      <Mentor.Screen
        name="PayoutMentor"
        component={PayoutScreen}
        options={{
          headerShown: true,
          headerTitle: 'Utbetaling',
          headerBackTitleVisible: true,
          headerBackTitle: 'Tilbake',
        }}
      />
      <Mentor.Screen
        name="HistoryMentor"
        component={HistoryScreen}
        options={{
          headerShown: true,
          headerTitle: 'Historikk',
          headerBackTitleVisible: true,
          headerBackTitle: 'Tilbake',
        }}
      />
      <Mentor.Screen
        name="ReviewsMentor"
        component={ReviewsScreen}
        options={{
          headerShown: true,
          headerTitle: 'Anmeldelser',
          headerBackTitleVisible: true,
          headerBackTitle: 'Tilbake',
        }}
      />
      <Mentor.Screen
        name="AppAboutMentor"
        component={AppAboutMentor}
        options={{
          headerShown: true,
          headerTitle: 'Om appen',
          headerBackTitleVisible: true,
          headerBackTitle: 'Tilbake',
        }}
      />
    </Mentor.Navigator>
  )
}

export default MentorNavigator
