/*
This file has a duplicate in backend/src/utils/price-helpers.ts
Any changes done here needs to be done in the other file as well
 */

export const MENTOR_HOURLY_RATE_IN_EURO = 35
export const STUDENT_RATE_45_MINUTES = 495

export const calculateMentorPayout = (durationInSeconds: number) => {
  const ratePerMinute = MENTOR_HOURLY_RATE_IN_EURO / 60
  const ratePerSecond = ratePerMinute / 60

  return Number((ratePerSecond * durationInSeconds).toFixed(2))
}

export const calculateStudentMoneySpent = (durationInSeconds: number) => {
  const ratePerMinute = STUDENT_RATE_45_MINUTES / 45
  const ratePerSecond = ratePerMinute / 60

  return Math.floor(ratePerSecond * durationInSeconds)
}
