import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import Tabs from '../../components/Tabs'
import ReviewsScreen from '../../screens/Reviews'
import MeetingRoomScreen from '../../screens/Room'
import RequestScreen from '../../screens/Room/request'
import ReservationScreen from '../../screens/Room/reservation'
import AddReview from '../../screens/Reviews/add'
import AppAboutStudent from '../../screens/Settings/appAboutStudent'
import useMeeting from '../../hooks/useMeeting'
import StudentMeetingHistoryScreen from '../../screens/Settings/studentMeetingHistory'
import StudentOrderHistoryScreen from '../../screens/Settings/studentOrderHistory'

const StudentNavigator = () => {
  const { data } = useMeeting()
  const Student = createNativeStackNavigator()

  if (data?.meeting)
    return (
      <Student.Navigator>
        {data.meeting.endedAt ? (
          <Student.Screen
            name="StudentAddReview"
            component={AddReview}
            options={{
              headerShown: false,
            }}
          />
        ) : (
          <Student.Screen
            name="Room"
            component={MeetingRoomScreen}
            options={{
              headerShown: false,
            }}
          />
        )}
      </Student.Navigator>
    )
  else if (data?.request)
    return (
      <Student.Navigator>
        <Student.Screen
          name="Request"
          component={RequestScreen}
          options={{
            headerShown: false,
          }}
        />
      </Student.Navigator>
    )
  else if (data?.reservation)
    return (
      <Student.Navigator>
        <Student.Screen
          name="Reservation"
          component={ReservationScreen}
          options={{
            headerShown: false,
          }}
        />
      </Student.Navigator>
    )
  else
    return (
      <Student.Navigator initialRouteName="Main">
        <Student.Screen name="Main" component={Tabs} options={{ headerShown: false }} />
        <Student.Screen
          name="MeetingHistory"
          component={StudentMeetingHistoryScreen}
          options={{
            headerShown: true,
            headerTitle: 'Møtehistorikk',
            headerBackTitleVisible: true,
            headerBackTitle: 'Tilbake',
          }}
        />
        <Student.Screen
          name="OrderHistory"
          component={StudentOrderHistoryScreen}
          options={{
            headerShown: true,
            headerTitle: 'Ordrehistorikk',
            headerBackTitleVisible: true,
            headerBackTitle: 'Tilbake',
          }}
        />
        <Student.Screen
          name="Reviews"
          component={ReviewsScreen}
          options={{
            headerShown: true,
            headerTitle: 'Anmeldelser',
            headerBackTitleVisible: true,
            headerBackTitle: 'Tilbake',
          }}
        />
        <Student.Screen
          name="AppAboutStudent"
          component={AppAboutStudent}
          options={{
            headerShown: true,
            headerTitle: 'Om appen',
            headerBackTitleVisible: true,
            headerBackTitle: 'Tilbake',
          }}
        />
      </Student.Navigator>
    )
}

export default StudentNavigator
