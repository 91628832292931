import * as React from 'react'
import Svg, { Circle, G, Path } from 'react-native-svg'

const ImageIcon = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      id="Component_4_23"
      data-name="Component 4 – 23"
      width="68"
      height="68"
      viewBox="0 0 68 68"
    >
      <G id="Group_5305" data-name="Group 5305">
        <G id="Group_5243" data-name="Group 5243">
          <Circle
            id="Ellipse_1104"
            data-name="Ellipse 1104"
            cx="34"
            cy="34"
            r="34"
            fill="#1379DD"
          />
        </G>
      </G>
      <G id="Group_5245" data-name="Group 5245" transform="translate(16.369 17.64)">
        <G id="Group_5244" data-name="Group 5244" transform="translate(0 0)">
          <Path
            id="Path_4396"
            data-name="Path 4396"
            d="M33.386,14.261H27.936l-1.669-3.337a2.242,2.242,0,0,0-2-1.224H15.959a2.242,2.242,0,0,0-2,1.224l-1.669,3.337H6.838a4.312,4.312,0,0,0-4.338,4.3V35.321a4.344,4.344,0,0,0,4.338,4.338H33.423a4.344,4.344,0,0,0,4.338-4.338V18.562A4.368,4.368,0,0,0,33.386,14.261ZM20.112,34.8a8.435,8.435,0,1,1,8.454-8.417A8.45,8.45,0,0,1,20.112,34.8Z"
            transform="translate(-2.5 -9.7)"
            fill="#fff"
          />
          <Path
            id="Path_4397"
            data-name="Path 4397"
            d="M41.213,39.1a6.713,6.713,0,1,0,6.713,6.713A6.746,6.746,0,0,0,41.213,39.1Z"
            transform="translate(-23.599 -29.339)"
            fill="#fff"
          />
        </G>
      </G>
    </Svg>
  )
}

export default ImageIcon
