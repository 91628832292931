import tw from '../../../lib/tailwind'
import { View, SafeAreaView, ActivityIndicator } from 'react-native'
import React from 'react'

const LoadingScreen = () => (
  <SafeAreaView style={tw`flex-1 bg-white`}>
    <View style={tw`flex items-center justify-center h-full bg-white p-5 rounded-lg m-3`}>
      <ActivityIndicator size={'large'} color="#0679DD" />
    </View>
  </SafeAreaView>
)

export default LoadingScreen
