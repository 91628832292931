import HomeScreen from '../../screens/Home'
import { Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import { FontAwesome } from '@expo/vector-icons'
import SettingsScreen from '../../screens/Settings'
import React from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import TimeScreen from '../../screens/Time'
import UserIcon from '../Icons/user'
import useRemainingTime from '../../hooks/useRemainingTime'

const Tab = createBottomTabNavigator()

const Tabs = () => {
  const { hours, minutes } = useRemainingTime()
  return (
    <Tab.Navigator
      initialRouteName="Home"
      screenOptions={() => ({
        tabBarActiveTintColor: '#0679DD',
        tabBarInactiveTintColor: 'gray',
        tabBarStyle: {
          zIndex: 49,
          position: 'relative',
        },
        tabBarShowLabel: false,
        style: {
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#FAFAFA',
          height: 100,
          elevation: 0,
          zIndex: -1,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        },
      })}
    >
      <Tab.Screen
        name="Time"
        component={TimeScreen}
        options={{
          headerShown: false,
          tabBarIcon: () => (
            <View
              style={tw`justify-center items-center border border-black rounded-full px-3 py-1 w-15`}
            >
              <Text style={tw`text-xs text-center text-black`}>
                {hours}:{minutes}
              </Text>
            </View>
          ),
        }}
      />
      <Tab.Screen
        name="Home"
        component={HomeScreen}
        options={{
          headerShown: false,
          tabBarIcon: ({ focused }) => (
            <View style={tw`justify-center items-center`}>
              <FontAwesome name="home" size={25} color={focused ? '#0679DD' : '#8E8E8E'} />
            </View>
          ),
        }}
      />
      <Tab.Screen
        name="Settings"
        component={SettingsScreen}
        options={{
          tabBarIcon: ({ focused }) => (
            <View style={tw`justify-center items-center`}>
              <UserIcon />
            </View>
          ),
        }}
      />
    </Tab.Navigator>
  )
}
export default Tabs
