import React, { useState } from 'react'
import {
  SafeAreaView,
  ScrollView,
  StatusBar,
  Text,
  TextInput,
  TouchableOpacity,
  View,
} from 'react-native'
import { useSWRConfig } from 'swr'
import tw from '../../../lib/tailwind'
import { handleError } from '../../utils/helpers'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import useMeeting from '../../hooks/useMeeting'
import ActionButton from '../../components/ActionButton'

const DeclineScreen = ({ navigation, route }: any) => {
  const [message, setMessage] = useState('')
  const [messageError, setMessageError] = useState<string>()
  const { data, declineRequest } = useMeeting()
  const reservationId: string = data?.request?.id ?? ''
  const handleDecline = async () => {
    if (message.trim() === '') {
      setMessageError('Du må oppgi grunn til avslaget')
      return
    }

    setMessageError('')

    try {
      await declineRequest({ reservationId, message })
    } catch (e) {
      handleError(e)
    }
  }
  return (
    <SafeAreaView style={tw.style(`flex-1 bg-white`, { paddingTop: StatusBar.currentHeight })}>
      <KeyboardAwareScrollView>
        <View style={tw`items-center p-3`}>
          <Text style={tw`text-3xl font-bold text-blue my-3`}>Du må gi en redegjørelse</Text>
          <Text style={tw`text-2xl font-bold mt-5`}>
            {/* Svar til {String(data?.request?.student?.firstName)}{' '} */}
            Svar til
          </Text>
          <TextInput
            style={tw`min-h-40 bg-grey-light w-full rounded-xl p-4 text-base my-5`}
            multiline={true}
            textAlignVertical={'top'}
            value={message}
            onChangeText={setMessage}
            placeholder="Begrunn avslag"
          />

          <ActionButton label={'Send'} onPress={handleDecline} />
          {messageError && <Text style={tw`text-red`}>{messageError}</Text>}
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )
}
export default DeclineScreen
