import { Text, View } from 'react-native'
import tw from '../../lib/tailwind'
import React from 'react'

type Props = {
  moneyEarned?: number
  meetingsCount?: number
}
const MentorStats = ({ moneyEarned = 0, meetingsCount = 0 }: Props) => {
  return (
    <View style={tw`flex-row m-3 py-5 rounded-xl shadow-lg bg-white items-center`}>
      <View style={tw`items-center justify-center flex-1 border-r border-grey-light`}>
        <Text style={tw`text-grey text-sm mb-1`}>Penger tjent</Text>
        <Text style={tw`text-black text-4xl font-bold`}>€{moneyEarned}</Text>
      </View>
      <View style={tw`items-center justify-center flex-1`}>
        <Text style={tw`text-grey text-sm mb-1`}>Møter gjennomført</Text>
        <Text style={tw`text-black text-4xl font-bold`}>{meetingsCount}</Text>
      </View>
    </View>
  )
}

export default MentorStats
