import { Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import { FontAwesome } from '@expo/vector-icons'
import React from 'react'

interface Props {
  value: string | undefined
}

const DisabledInput = ({ value }: Props) => {
  return (
    <View
      style={tw`flex flex-row justify-between items-center border border-grey-light rounded-lg p-3 my-2`}
    >
      <Text style={tw`text-grey`}>{value}</Text>
      <FontAwesome name="lock" size={20} style={tw`text-grey`} />
    </View>
  )
}

export default DisabledInput
