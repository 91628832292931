export default class CloudinaryClient {
  private apiKey = '591322476459383'
  private cloudName = 'minmentor-no'
  private uploadEndpoint = `https://api.cloudinary.com/v1_1/${this.cloudName}/upload`

  uploadImage(uri: string, base64: string) {
    const uriArr = uri.split('.')
    const fileType = uriArr[uriArr.length - 1]
    const file = `data:${fileType};base64,${base64}`
    const formData = new FormData()
    formData.append('file', file)
    formData.append('upload_preset', 'minmentor-avatar')
    formData.append('folder', 'mentor-avatars')

    return fetch(this.uploadEndpoint, {
      method: 'post',
      body: formData,
    })
  }
}
