import React, { useEffect, useState } from 'react'
import {
  ActivityIndicator,
  Pressable,
  SafeAreaView,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import tw from '../../../lib/tailwind'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { EducationLevel } from '../../generated/graphql'
import useTopics from '../../hooks/useTopics'
import { FontAwesome } from '@expo/vector-icons'
import ExpoCheckbox from 'expo-checkbox'
import ActionButton from '../../components/ActionButton'
import NestedListView, { NestedRow, INode } from 'react-native-nested-listview'

const Topics = ({ handleTopics, topicIds, toggle }: any) => {
  const educationLevels = [
    {
      name: 'Grunnskole',
      key: EducationLevel.Elementary,
    },
    {
      name: 'Videregående',
      key: EducationLevel.MiddleSchool,
    },
    {
      name: 'Høyere',
      key: EducationLevel.HighSchool,
    },
  ]
  const { topics, isValidating } = useTopics()

  const [selectedLevel, setSelectedLevel] = useState(EducationLevel.Elementary)
  const [selectedTopics, setSelectedTopics] = useState<string[]>(topicIds)
  const [isSaving, setIsSaving] = useState(false)
  const [open, setOpen] = useState('')
  const filteredTopics = topics?.topics?.filter((topic) => topic.level === selectedLevel)

  const handleSelectLevel = async (level: EducationLevel) => {
    setSelectedLevel(level)
  }
  const handleSelectTopic = async (topicId: string, subTopicsCount?: number) => {
    if (subTopicsCount === 0 ||!subTopicsCount) {
      setSelectedTopics((prevTopics) =>
        prevTopics.some((topic) => topic === topicId)
          ? prevTopics.filter((topic) => topic !== topicId)
          : [...prevTopics, topicId]
      )
    } else {
      if (open && open === topicId) setOpen('')
      else setOpen(topicId)
    }
  }

  const handleSave = async () => {
    setIsSaving(true)
    await handleTopics(selectedTopics)
    setIsSaving(false)
  }
  const renderNode = (topic: INode, level?: number) => (
    // @ts-ignore
    <NestedRow
      level={level}
      style={tw`justify-between items-center flex-row py-3 border-0 border-b-2 border-grey-light pr-1`}
      paddingLeftIncrement={10}
    >
      <>
        <Text style={tw`text-black text-lg pr-10`}>{topic.name}</Text>
        {topic.subTopicsCount ? (
          <FontAwesome
            name={topic.opened ? 'angle-down' : 'angle-right'}
            size={24}
            style={tw.style(`mr-2`)}
          />
        ) : (
          <ExpoCheckbox
            value={selectedTopics?.some((topicId) => topicId === topic.id) ?? false}
            style={tw`rounded-full w-6 h-6 -ml-5`}
            onValueChange={() => handleSelectTopic(topic.id, topic.subTopicsCount)}
          />
        )}
      </>
    </NestedRow>
  )

  return (
    <View
      style={tw`min-h-full flex-1 z-10 w-full py-8 rounded-t-2xl bottom-0 shadow-lg left-0 bg-white `}
    >
      <View style={tw`flex-1 h-full`}>
        <View style={tw`px-3 flex-row justify-center`}>
          {educationLevels.map(({ name, key }) => (
            <TouchableOpacity
              key={key}
              style={tw.style(
                `mx-2 px-2 py-3 shadow-md rounded-lg ${
                  selectedLevel === key ? 'bg-blue text-white' : 'bg-white'
                }`
              )}
              onPress={() => handleSelectLevel(key)}
            >
              <Text
                style={tw.style(`font-bold ${selectedLevel === key ? 'text-white' : 'text-black'}`)}
              >
                {name}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <View style={tw`flex-1 px-3 mb-2 mt-6`}>
          {isValidating ? (
            <View>
              <ActivityIndicator style={tw`my-4`} size="large" />
              <Text style={tw`text-center`}>Henter fagliste</Text>
            </View>
          ) : (
            <NestedListView
              data={filteredTopics}
              getChildrenName={(topic) => 'subTopics'}
              onNodePressed={(topic) => handleSelectTopic(topic.id, topic.subTopicsCount)}
              renderNode={renderNode}
            />
          )}
        </View>
      </View>

      <View
        style={tw`mb-7 flex self-end w-full px-3 pt-4 pb-6 bottom-0 shadow-lg left-0 bg-white `}
      >
        <ActionButton label={'Lukk'} color={'blue'} onPress={handleSave} />
      </View>
    </View>
  )
}

export default Topics
