import React, { useState } from 'react'
import { SafeAreaView, StatusBar, Text, TouchableOpacity, View } from 'react-native'
import tw from '../../../lib/tailwind'
import ActionButton from '../../components/ActionButton'
import CountdownTimer from '../../components/CountdownTimer'
import useMeeting from '../../hooks/useMeeting'

const ReceivedRequestScreen = ({ navigation }: any) => {
  const { data, acceptMeetingRequest } = useMeeting()
  const [acceptMeetingIsLoading, setAcceptMeetingIsLoading] = useState<boolean>(false)

  const reservationId: string = data?.request?.id ?? ''
  const parsedDate = new Date(data?.request?.requestSentAt)
  const message = data?.request?.message ? '"' + data?.request?.message + '"' : ''

  const handleAccept = async () => {
    setAcceptMeetingIsLoading(true)
    await acceptMeetingRequest({ reservationId })
    setAcceptMeetingIsLoading(false)
  }

  return (
    <SafeAreaView style={tw.style(`flex-1 bg-white`, { paddingTop: StatusBar.currentHeight })}>
      <View style={tw`items-center p-3`}>
        <Text style={tw`text-3xl font-bold text-blue my-3`}>Du har en forespørsel</Text>
        <Text style={tw`my-3`}>
          Må bekreftes innen:&nbsp;
          {Boolean(data?.request?.requestSentAt) && (
            <CountdownTimer parsedDate={parsedDate.getTime()} minutes={15} />
          )}
        </Text>
        <Text style={tw`text-2xl font-bold mt-5`}>Fra {data?.request?.student?.firstName} </Text>
        <Text style={tw`text-lg my-3`}>{message}</Text>
        <ActionButton
          isLoading={acceptMeetingIsLoading}
          label="Godta og start"
          onPress={() => !acceptMeetingIsLoading && handleAccept()}
        />
        <ActionButton
          label={'Avvis'}
          color={'red'}
          onPress={() => navigation.navigate('DeclineRequest')}
        />
      </View>
    </SafeAreaView>
  )
}
export default ReceivedRequestScreen
