import * as React from 'react'
import Svg, { Circle, G, Defs, Path, ClipPath, Rect, Line } from 'react-native-svg'

const FileIcon = () => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="36"
      height="47.946"
      viewBox="0 0 36 47.946"
    >
      <Defs>
        <ClipPath id="clip-path">
          <Rect
            id="Rectangle_1370"
            data-name="Rectangle 1370"
            width="36"
            height="47.946"
            transform="translate(0 0)"
            fill="none"
            stroke="#0679dd"
            strokeWidth="1"
          />
        </ClipPath>
      </Defs>
      <G id="Group_5406" data-name="Group 5406" transform="translate(0 0)" opacity="0.7">
        <G id="Group_5307" data-name="Group 5307" clipPath="url(#clip-path)">
          <Path
            id="Path_4398"
            data-name="Path 4398"
            d="M26,1V8a2,2,0,0,0,2,2h7"
            fill="none"
            stroke="#0679dd"
            strokeWidth="2"
          />
          <Path
            id="Path_4399"
            data-name="Path 4399"
            d="M25.172,1H3A2,2,0,0,0,1,3V44.946a2,2,0,0,0,2,2H33a2,2,0,0,0,2-2V10.828a2,2,0,0,0-.585-1.414L26.586,1.586A2,2,0,0,0,25.172,1Z"
            fill="none"
            stroke="#0679dd"
            strokeWidth="2"
          />
          <Circle
            id="Ellipse_1153"
            data-name="Ellipse 1153"
            cx="13"
            cy="13"
            r="13"
            transform="translate(5 15.973)"
            fill="none"
            stroke="#0679dd"
            strokeWidth="2"
          />
          <Path
            id="Path_4400"
            data-name="Path 4400"
            d="M15,25.973l3-3,3,3"
            fill="none"
            stroke="#0679dd"
            strokeWidth="2"
          />
          <Line
            id="Line_29"
            data-name="Line 29"
            y1="7.991"
            transform="translate(18 23.982)"
            fill="none"
            stroke="#0679dd"
            strokeWidth="2"
          />
          <Path
            id="Path_4401"
            data-name="Path 4401"
            d="M23,32.973v3H13v-3"
            fill="none"
            stroke="#0679dd"
            strokeWidth="2"
          />
        </G>
      </G>
    </Svg>
  )
}
export default FileIcon
