import { Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import BouncyCheckbox from 'react-native-bouncy-checkbox'
import React from 'react'

const StripeAccountProgress = ({
  status,
}: {
  status: 'NOT_CONNECTED' | 'ONBOARDING' | 'PENDING_APPROVAL' | 'COMPLETE'
}) => {
  return (
    <View style={tw`p-3 text-center`}>
      <Text style={tw`my-2`}>
        <BouncyCheckbox
          size={25}
          fillColor="#0679DD"
          unfillColor="#FFFFFF"
          text="Opprette konto"
          iconStyle={{ borderColor: '#0679DD' }}
          textStyle={{ fontWeight: status === 'NOT_CONNECTED' ? 'bold' : 'normal' }}
          isChecked={
            status === 'ONBOARDING' || status === 'PENDING_APPROVAL' || status === 'COMPLETE'
          }
          disabled={true}
        />
      </Text>
      <Text style={tw`my-2`}>
        <BouncyCheckbox
          size={25}
          fillColor="#0679DD"
          unfillColor="#FFFFFF"
          text="Fyll ut nødvendig informasjon i Stripe"
          iconStyle={{ borderColor: '#0679DD' }}
          textStyle={{ fontWeight: status === 'ONBOARDING' ? 'bold' : 'normal' }}
          isChecked={status === 'PENDING_APPROVAL' || status === 'COMPLETE'}
          disabled={true}
        />
      </Text>
      <Text style={tw`my-2`}>
        <BouncyCheckbox
          size={25}
          fillColor="#0679DD"
          unfillColor="#FFFFFF"
          text="Vent på godkjenning"
          iconStyle={{ borderColor: '#0679DD' }}
          textStyle={{ fontWeight: status === 'PENDING_APPROVAL' ? 'bold' : 'normal' }}
          isChecked={status === 'COMPLETE'}
          disabled={true}
        />
      </Text>
      <Text style={tw`my-2 text-bold`}>
        <BouncyCheckbox
          size={25}
          fillColor="#0679DD"
          unfillColor="#FFFFFF"
          text="Kontoen er klar"
          iconStyle={{ borderColor: '#0679DD' }}
          textStyle={{ fontWeight: status === 'COMPLETE' ? 'bold' : 'normal' }}
          isChecked={status === 'COMPLETE'}
          disabled={true}
        />
      </Text>
    </View>
  )
}

export default StripeAccountProgress
