import {
  KeyboardAvoidingView,
  Pressable,
  Switch,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View,
  Keyboard,
  Platform,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from 'react-native'
import React, { useEffect, useRef } from 'react'
import tw from '../../../lib/tailwind'
import { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import { useState } from 'react'
import { RoleValue } from '../../generated/graphql'
import useMe from '../../hooks/useMe'
import { useLogout } from '../../hooks/useLogout'
import ActionButton from '../../components/ActionButton'
import DisabledInput from '../../components/Input/DisabledInput'

const RegisterScreen = () => {
  const { setUserRole } = useMe()
  const { me, setLoggedIn, setMe } = useContext<AuthContextValue>(AuthContext)
  const [selectedRole, setSelectedRole] = useState<RoleValue | null>(null)
  const [setUserRoleIsLoading, setSetUserRoleIsLoading] = useState(false)
  const [setUserRoleError, setSetUserRoleError] = useState<any>(null)

  const onSavePress = async () => {
    if (!selectedRole) {
      // Must select role before saving.
      return
    }

    try {
      setSetUserRoleError(null)
      setSetUserRoleIsLoading(true)
      const res = await setUserRole(selectedRole)
      setMe(res?.me ?? null)
      setLoggedIn()
    } catch (error) {
      setSetUserRoleIsLoading(false)
      setSetUserRoleError(error)
    }
  }

  return (
    <KeyboardAvoidingView
      style={tw`flex-1 bg-white px-5`}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
    >
      <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
        <View style={tw`flex-1 h-full justify-center items-center`}>
          <Text style={tw`text-2xl font-bold pb-6`}>Snart klar!</Text>
          <View style={tw`w-full mb-3`}>
            <Text style={tw`text-xs font-bold mb-1`}>Fornavn</Text>
            <DisabledInput value={me?.firstName} />
          </View>
          <View style={tw`w-full mb-3`}>
            <Text style={tw`text-xs font-bold mb-1`}>Etternavn</Text>
            <DisabledInput value={me?.lastName} />
          </View>
          <View style={tw`w-full mb-3`}>
            <Text style={tw`text-xs font-bold mb-1`}>Telefon</Text>
            <DisabledInput value={me?.phone} />
          </View>
          <View style={tw`w-full mb-3`}>
            <Text style={tw`text-xs font-bold mb-1`}>E-post</Text>
            <DisabledInput value={me?.email} />
          </View>
          <Text style={tw`text-xl font-bold text-center mt-6`}>Jeg er</Text>
          <View style={tw`flex-row rounded-full w-full bg-grey-light p-1 py-1 mt-4 mb-4`}>
            <TouchableOpacity
              style={tw.style(
                'bg-transparent rounded-tl-full rounded-bl-full p-2 py-5 rounded-r-none w-1/2',
                selectedRole === RoleValue.Student ? 'bg-white border border-blue' : ''
              )}
              onPress={() => setSelectedRole(RoleValue.Student)}
            >
              <Text style={tw`text-center`}>Elev</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={tw.style(
                'bg-transparent rounded-tr-full rounded-br-full rounded-l-none p-2 py-5 w-1/2',
                selectedRole === RoleValue.Mentor ? 'bg-white border border-blue' : ''
              )}
              onPress={() => setSelectedRole(RoleValue.Mentor)}
            >
              <Text style={tw`text-center`}>Lærer</Text>
            </TouchableOpacity>
          </View>

          <ActionButton
            label={'Start'}
            color={'blue'}
            disabled={!selectedRole}
            onPress={onSavePress}
            isLoading={setUserRoleIsLoading}
          />
          {setUserRoleError && <Text style={tw`text-red mt-2`}>{String(setUserRoleError)}</Text>}
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  )
}

export default RegisterScreen
