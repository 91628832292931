import React, { useContext } from 'react'
import { FlatList, SafeAreaView, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import { calculateMentorPayout } from '../../utils/price-helpers'
import { format } from 'date-fns'
import MentorStats from '../../components/MentorStats'
import useMentorProfile from '../../hooks/useMentorProfile'

const HistoryScreen = ({ navigation }: any) => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const { data } = getSdk().useMentorMeetingHistory('mentorMeetingHistory')
  const { mentorProfile } = useMentorProfile()

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <MentorStats
        moneyEarned={mentorProfile?.moneyEarned ?? 0}
        meetingsCount={mentorProfile?.meetingsCount ?? 0}
      />

      <View style={tw`flex-1 mx-3 my-5`}>
        <View style={tw`border-b border-grey-light pb-3`}>
          <Text style={tw`text-lg font-bold`}>Historikk</Text>
        </View>
        {!data?.mentorMeetingHistory?.length && (
          <Text style={tw`text-center py-2`}>Ingenting å vise enda</Text>
        )}
        <FlatList
          data={data?.mentorMeetingHistory}
          renderItem={({ item }) => (
            <View style={tw`py-4 border-b border-grey-light`}>
              <View style={tw`flex-row flex-1 justify-between`}>
                <Text style={tw`text-xs text-grey`}>
                  {item?.meeting?.createdAt
                    ? format(new Date(item?.meeting?.createdAt), 'dd. MMM')
                    : ''}
                </Text>
                <Text style={tw`text-xs text-grey`}>
                  {item?.meeting?.createdAt
                    ? format(new Date(item?.meeting?.createdAt), 'HH:mm')
                    : ''}{' '}
                  -{' '}
                  {item?.meeting?.createdAt
                    ? format(new Date(item?.meeting?.createdAt), 'HH:mm')
                    : ''}
                </Text>
              </View>
              <View style={tw`flex-row flex-1 justify-between`}>
                <Text style={tw`text-lg font-bold leading-none pt-2`}>
                  {item?.meeting?.participants[0].firstName}
                </Text>
                <View style={tw`flex-row items-end`}>
                  <Text style={tw`text-xs text-grey mr-2`}>
                    {Math.floor(item?.durationInSeconds / 60)} minutter
                  </Text>
                  <Text style={tw`text-lg font-bold leading-none`}>
                    €{calculateMentorPayout(item?.durationInSeconds)}
                  </Text>
                </View>
              </View>
              <Text style={tw`text-sm leading-none pt-2`}>
                Status:{' '}
                {item?.payout?.id
                  ? 'Utbetalt. Pengene er på vei til din bankkonto.'
                  : 'Venter på utbetaling. Det kan ta noen dager før utbetalingen dukker opp i din Stripe-konto.'}
              </Text>
            </View>
          )}
        />
      </View>
    </SafeAreaView>
  )
}

export default HistoryScreen
