import { GraphQLClient } from 'graphql-request'
import { getSdkWithHooks } from '../generated/graphql'
import Constants from 'expo-constants'

const { API_URL } = Constants.manifest?.extra!

const getAuthorizedSdk = (token: string | null) => {
  const headers: Record<string, string> = {
    'Content-Type': 'application/json',
  }

  if (token && token !== 'logged_out') {
    headers.Authorization = `Bearer ${token}`
  }

  return getSdkWithHooks(new GraphQLClient(`${API_URL}/api`, { headers }))
}
export default getAuthorizedSdk
