import React from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import LoginScreen from '../screens/Login'

const LoggedOutNavigator = () => {
  const LoggedOut = createNativeStackNavigator()

  return (
    <LoggedOut.Navigator initialRouteName="Login">
      <LoggedOut.Screen
        name="Login"
        component={LoginScreen}
        options={{
          headerShown: false,
        }}
      />
    </LoggedOut.Navigator>
  )
}

export default LoggedOutNavigator
