import React from 'react'
import { SafeAreaView, View } from 'react-native'
import tw from '../../../lib/tailwind'
import DocumentUploader from '../../components/DocumentUploader'

const AboutScreen = () => {
  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <View style={tw`flex-1 px-3`}>
        <DocumentUploader />
      </View>
    </SafeAreaView>
  )
}

export default AboutScreen
