import React, { useState } from 'react'
import { ActivityIndicator, Button, Image, Text, TouchableOpacity, View, Alert } from 'react-native'
import tw from '../../../lib/tailwind'
import ImageIcon from '../Icons/image'
import * as ImagePicker from 'expo-image-picker'
import CloudinaryClient from '../../utils/CloudinaryClient'
import useMentorProfile from '../../hooks/useMentorProfile'

const ImageUpload = ({ avatarUrl }: { avatarUrl?: string | null }) => {
  const [loading, setLoading] = useState(false)
  const cloudinaryClient = new CloudinaryClient()
  const { updateMentorProfile } = useMentorProfile()

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    const result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    })

    if (!result.cancelled) {
      setLoading(true)
      const { uri, base64 } = result
      if (base64) {
        const res = await cloudinaryClient.uploadImage(uri, base64).then((res) => res.json())
        await updateMentorProfile({ avatarUrl: res.url })
        setLoading(false)
      }
    }
  }

  return (
    <View style={tw`m-3 flex-1`}>
      <TouchableOpacity
        style={tw`flex-1 py-8 shadow-lg bg-white rounded-3xl justify-center items-center`}
        onPress={pickImage}
      >
        {loading && (
          <ActivityIndicator size="large" color="#0679DD" style={tw`w-32 h-32 rounded-full`} />
        )}
        {!loading && !avatarUrl && <ImageIcon />}
        {!loading && avatarUrl && (
          <Image source={{ uri: avatarUrl }} style={tw`w-32 h-32 rounded-full`}></Image>
        )}
        <Text style={tw`mt-3 text-center`}>
          {loading ? 'Oppdaterer bilde..' : avatarUrl ? 'Bytt profilbilde' : 'Legg til profilbilde'}
        </Text>
      </TouchableOpacity>
    </View>
  )
}

export default ImageUpload
