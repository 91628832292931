import React, { useEffect, useState } from 'react'
import { SafeAreaView, Text, TextInput, View } from 'react-native'
import tw from '../../../lib/tailwind'
import { handleError } from '../../utils/helpers'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import useMe from '../../hooks/useMe'
import useMentorProfile from '../../hooks/useMentorProfile'
import ActionButton from '../../components/ActionButton'
import DisabledInput from '../../components/Input/DisabledInput'

const AboutScreen = () => {
  const [updateProfileIsLoading, setUpdateProfileIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const { me } = useMe()
  const { mentorProfile, updateMentorProfile } = useMentorProfile()

  const [mentorData, setMentorData] = useState({
    firstName: me?.firstName,
    lastName: me?.lastName,
    phone: me?.phone,
    email: me?.email,
    title: mentorProfile?.title ?? '',
    orgNumber: mentorProfile?.orgNumber ?? '',
    bio: mentorProfile?.bio ?? '',
  })

  useEffect(() => {
    if (me) {
      setMentorData((prevState) => ({
        ...prevState,
        firstName: me.firstName,
        lastName: me.lastName,
        phone: me.phone,
        email: me.email,
      }))
    }
  }, [me])
  const handleUpdateMentor = async () => {
    try {
      setUpdateProfileIsLoading(true)
      await updateMentorProfile({
        title: String(mentorData.title),
        orgNumber: String(mentorData.orgNumber),
        bio: String(mentorData.bio),
      })
      setIsSuccess(true)
    } catch (e) {
      handleError(e)
    } finally {
      setUpdateProfileIsLoading(false)
    }
  }

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), 2500)
    }
  }, [isSuccess])

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <KeyboardAwareScrollView style={tw`flex-1`}>
        <View style={tw`px-3 mb-2 mt-6`}>
          <Text style={tw`text-xs font-bold`}>Fornavn</Text>
          <DisabledInput value={mentorData.firstName} />
        </View>
        <View style={tw`px-3 my-2`}>
          <Text style={tw`text-xs font-bold`}>Etternavn</Text>
          <DisabledInput value={mentorData.lastName} />
        </View>
        <View style={tw`px-3 my-2`}>
          <Text style={tw`text-xs font-bold`}>Telefon</Text>
          <DisabledInput value={mentorData.phone} />
        </View>
        <View style={tw`px-3 my-2`}>
          <Text style={tw`text-xs font-bold`}>E-post</Text>
          <DisabledInput value={mentorData.email} />
        </View>
        <Text style={tw`p-4`}>
          Følgende felt er påkrevd å fylle ut før du kan bli godkjent som mentor i appen:
          Lærertittel, org.nr. eller foretaksnummer, kontonummer og en kort beskrivelse av deg selv.
        </Text>
        <View style={tw`px-3 my-2`}>
          <Text style={tw`text-xs font-bold`}>Lærertittel</Text>
          <TextInput
            style={tw`border border-grey text-black rounded-lg p-3 my-2`}
            value={mentorData.title}
            onChangeText={(newTitle) =>
              setMentorData((mentorData) => ({
                ...mentorData,
                title: newTitle,
              }))
            }
          />
        </View>
        <View style={tw`px-3 my-2`}>
          <Text style={tw`text-xs font-bold`}>Org.nr.</Text>
          <TextInput
            style={tw`border border-grey text-black rounded-lg p-3 my-2`}
            value={mentorData.orgNumber}
            keyboardType={'number-pad'}
            onChangeText={(newOrgNumber) =>
              setMentorData((mentorData) => ({
                ...mentorData,
                orgNumber: newOrgNumber,
              }))
            }
          />
        </View>

        <View style={tw`px-3 my-2 pb-24`}>
          <View style={tw`flex flex-row justify-between`}>
            <Text style={tw`text-xs font-bold`}>Om deg</Text>
            <Text style={tw.style(`text-xs font-bold`, mentorData.bio.length > 115 && 'text-red')}>
              {120 - mentorData.bio.length} tegn igjen
            </Text>
          </View>
          <TextInput
            style={tw`min-h-40 border border-grey text-black rounded-lg p-3 my-2`}
            multiline={true}
            textAlignVertical={'top'}
            value={mentorData.bio}
            maxLength={120}
            onChangeText={(newBio) =>
              setMentorData((mentorData) => ({
                ...mentorData,
                bio: newBio,
              }))
            }
          />
        </View>
      </KeyboardAwareScrollView>
      <View
        style={tw`absolute z-10 flex-1 w-full px-3 pt-4 pb-6 rounded-t-2xl bottom-0 shadow-lg left-0 bg-white`}
      >
        <ActionButton
          label={
            updateProfileIsLoading ? 'Oppdaterer' : isSuccess ? 'Oppdatering vellykket' : 'Oppdater'
          }
          color={'blue'}
          disabled={updateProfileIsLoading}
          onPress={() => handleUpdateMentor()}
          isLoading={updateProfileIsLoading}
        />
      </View>
    </SafeAreaView>
  )
}

export default AboutScreen
