import React, { useContext } from 'react'
import { ActivityIndicator, FlatList, SafeAreaView, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import { calculateStudentMoneySpent } from '../../utils/price-helpers'
import { format } from 'date-fns'
import StudentStats from '../../components/StudentStats'

const StudentMeetingHistoryScreen = ({ navigation }: any) => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const { data, isValidating } = getSdk().useStudentMeetingHistory('studentMeetingHistory')

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <StudentStats meetingsCount={data?.studentMeetingHistory?.length} />
      <View style={tw`flex-1 mx-3 my-5`}>
        <View style={tw`border-b border-grey-light pb-3`}>
          <Text style={tw`text-lg font-bold`}>Møtehistorikk</Text>
        </View>

        {isValidating ? (
          <ActivityIndicator style={tw`my-4`} />
        ) : !data?.studentMeetingHistory?.length ? (
          <Text style={tw`text-center py-2`}>Ingenting å vise enda</Text>
        ) : (
          <FlatList
            data={data?.studentMeetingHistory}
            renderItem={({ item }) => (
              <View style={tw`py-4 border-b border-grey-light`}>
                <View style={tw`flex-row flex-1 justify-between`}>
                  <Text style={tw`text-xs text-grey`}>
                    {format(new Date(item?.meeting?.createdAt), 'dd. MMM')}
                  </Text>
                  <Text style={tw`text-xs text-grey`}>
                    {format(new Date(item?.meeting?.createdAt), 'HH:mm')} -{' '}
                    {format(new Date(item?.meeting?.createdAt), 'HH:mm')}
                  </Text>
                </View>
                <View style={tw`flex-row flex-1 justify-between`}>
                  <Text style={tw`text-lg font-bold leading-none pt-2`}>
                    {item?.meeting?.host?.firstName}
                  </Text>
                  <View style={tw`flex-row items-end`}>
                    <Text style={tw`text-xs text-grey mr-2`}>
                      {Math.floor(item?.durationInSeconds / 60)} minutter
                    </Text>
                    <Text style={tw`text-lg font-bold leading-none`}>
                      {calculateStudentMoneySpent(item?.durationInSeconds)} kr
                    </Text>
                  </View>
                </View>
              </View>
            )}
          />
        )}
      </View>
    </SafeAreaView>
  )
}

export default StudentMeetingHistoryScreen
