import React, { useContext } from 'react'
import AuthContext, { AuthContextValue } from '../contexts/auth'
import RegisterNavigator from './Register'
import LoggedInNavigator from './LoggedIn'
import LoggedOutNavigator from './LoggedOut'
import { NavigationContainer } from '@react-navigation/native'
import { Platform } from 'react-native'

const Navigator = () => {
  const { loginState } = useContext<AuthContextValue>(AuthContext)

  const config = {
    screens: {
      Login: 'login',
      Register: 'register',

      // Screens and stacks Related to student
      Payment: 'payment/:orderId',
      StudentNavigator: 'student',
      StudentAddReview: 'student-add-review',
      Room: 'student-room',
      Request: 'student-request',
      Reservation: 'reservation',
      Main: 'main',
      MeetingHistory: 'meetings',
      OrderHistory: 'orders',
      Reviews: 'reviews',
      AppAboutStudent: 'app-about-student',
      Home: 'home',
      Time: 'time',
      Settings: 'settings',

      // Screens and stacks related to mentor
      MentorAddReview: 'mentor-add-review',
      MentorRoom: 'mentor-room',
      ReceivedRequest: 'mentor-request',
      DeclineRequest: 'decline-request',
      Mentor: 'mentor',
      MentorSelectTopics: 'select-topics',
      AboutMentor: 'mentor-about',
      DocumentsMentor: 'documents',
      PayoutMentor: 'payout',
      HistoryMentor: 'mentor-history',
      ReviewsMentor: 'mentor-reviews',
      AppAboutMentor: 'app-about-mentor',
    },
  }

  const linking = {
    prefixes: [],
    config,
  }

  return (
    <NavigationContainer linking={Platform.OS === 'web' ? linking : undefined}>
      {loginState === 'logged_in' && <LoggedInNavigator />}
      {loginState === 'logged_out' && <LoggedOutNavigator />}
      {loginState === 'logged_in_unregistered' && <RegisterNavigator />}
    </NavigationContainer>
  )
}

export default Navigator
