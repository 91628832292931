import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import getAuthorizedSdk from '../../utils/sdk'
import { SdkWithHooks, UserFragmentFragment } from '../../generated/graphql'
import { SWRConfig } from 'swr'
import * as SplashScreen from 'expo-splash-screen'
import { Alert } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

const AuthContext = React.createContext<any>(null)

type LoginState = 'pending' | 'logged_in' | 'logged_in_unregistered' | 'logged_out'

export interface AuthContextValue {
  loginState: LoginState
  getSdk: () => SdkWithHooks
  setLogout: () => void
  setLoggedIn: () => void
  setToken: React.Dispatch<React.SetStateAction<string | null>>
  setMe: React.Dispatch<React.SetStateAction<UserFragmentFragment | null>>
  me: UserFragmentFragment | null
  refetchMe: () => Promise<void>
  handleAuthErrors: (error: any, resetError?: boolean) => void
  authError: string | null
  setAuthError: React.Dispatch<React.SetStateAction<string | null>>
}

const fetchTokenFromStorage = () => {
  return AsyncStorage.getItem('jwt')
}

const isExpiredTokenError = (error: any) =>
  new Error(error).message.includes('jwt expired') ||
  !!error?.response?.errors?.some((err: any) => err.message.includes('jwt expired'))

const isUserNotFoundError = (error: any) =>
  error?.message?.includes('Could not find a user with provided token') ||
  !!error?.response?.errors?.some((err: any) =>
    err.message.includes('Could not find a user with provided token')
  )

const isNetworkError = (error: any) =>
  error.message === 'Network request failed' ||
  !!error?.response?.errors?.some((err: any) => {
    return err.message.includes('Network request failed') || err === 'Network request failed'
  })

export const AuthProvider = ({ children }: any) => {
  const [loginState, setLoginState] = useState<LoginState>('pending')
  const [token, setToken] = useState<string | null>(null)
  const [me, setMe] = useState<UserFragmentFragment | null>(null)
  const [authError, setAuthError] = useState<string | null>(null)

  // Hide the splash screen until everything is loaded
  void SplashScreen.preventAutoHideAsync()

  // All queries to the API must be authorized. Since getting the token is an async operation, we will do it here and share the context with the rest of the app.
  const getSdk = () => getAuthorizedSdk(token)

  const handleAuthErrors = (error: any, resetError: boolean | undefined = false) => {
    if (isExpiredTokenError(error)) {
      setLoginState('logged_out')
      setToken(null)
      AsyncStorage.removeItem('jwt').then(() => {
        Alert.alert('Din økt har utløpt', 'Vennligst logg inn på nytt.')
      })
    } else if (isUserNotFoundError(error)) {
      setToken(null)
      setLoginState('logged_out')
      AsyncStorage.removeItem('jwt').then(() => {
        Alert.alert('Finner ikke bruker', 'Vennligst logg inn på nytt for å opprette ny bruker.')
      })
    } else if (isNetworkError(error)) {
      if (process.env.NODE_ENV === 'development') {
        console.log('Nettverksfeil', { error })
      }
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.log(new Error(error).message.includes('jwt expired'))
        console.log('Ukjent feil', { error })
      }
    }

    void SplashScreen.hideAsync()
    return
  }

  const fetchMe = async (token: string) => {
    const sdk = () => getAuthorizedSdk(token)

    try {
      const data = await sdk().me()

      setMe(data.me ?? null)
      return data.me ?? null
    } catch (error: any) {
      throw new Error(error)
    }
  }

  const refetchMe = async () => {
    if (token) await fetchMe(token)
  }

  const authContext: AuthContextValue = {
    loginState,
    setToken,
    getSdk,
    setLogout: () => setLoginState('logged_out'),
    setLoggedIn: () => setLoginState('logged_in'),
    me,
    refetchMe,
    setMe,
    handleAuthErrors,
    authError,
    setAuthError,
  }

  useEffect(() => {
    // After getting the token and finding the correct login state, we can hide the splash screen
    fetchTokenFromStorage().then((token) => {
      if (!token) {
        // No token found, user is not logged in
        setLoginState('logged_out')
        void SplashScreen.hideAsync()
      } else {
        fetchMe(token)
          .then((me) => {
            setToken(token)
            if (me?.role) {
              // Since the user already have a role it means that they can be logged in
              setLoginState('logged_in')
              void SplashScreen.hideAsync()
            } else {
              // User exists, but has not selected a role yet
              setLoginState('logged_in_unregistered')
              void SplashScreen.hideAsync()
            }
          })
          .catch((error) => {
            handleAuthErrors(error)
          })
      }
      return token
    })
  }, [token])

  const onError = (error: any, key: any) => {
    handleAuthErrors(error)
    return
  }

  return (
    <SWRConfig value={{ onError }}>
      <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
    </SWRConfig>
  )
}

export default AuthContext
