import { Link } from '@react-navigation/native'
import { useContext, useEffect, useReducer } from 'react'
import { ActivityIndicator, Button, Text, View } from 'react-native'
import AuthContext, { AuthContextValue } from '../contexts/auth'
import { PaymentStatusValue } from '../generated/graphql'

enum PaymentStatus {
  PENDING,
  SUCCESS,
  CANCELLED,
  ERROR,
}

enum Action {
  UPDATE_STATUS,
}

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case Action.UPDATE_STATUS:
      return {
        status: action.status,
      }
    default:
      return state
  }
}

const Payment = ({ navigation, route }: any) => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)

  // if (!route?.params?.orderId) {
  //   navigation.navigate('StudentNavigator')
  // }

  const { useOrderPaymentStatus } = getSdk()

  const [state, dispatch] = useReducer(reducer, { status: PaymentStatus.PENDING })

  // If there is not orderId we should just navigate to main screen
  const { data, error } = useOrderPaymentStatus(
    'payment-order',
    { id: route.params.orderId || '123' },
    {
      isPaused() {
        return state.status !== PaymentStatus.PENDING
      },
      onSuccess: () => console.log('Retrying :)'),
      onError: () => console.log('Error :('),

      refreshInterval: 2000,
    }
  )

  useEffect(() => {
    console.log(data)

    if (!data) {
      return
    }

    if (error) {
      dispatch({ type: Action.UPDATE_STATUS, status: PaymentStatus.ERROR })
    } else if (data.orderPaymentStatus === PaymentStatusValue.Paid) {
      dispatch({ type: Action.UPDATE_STATUS, status: PaymentStatus.SUCCESS })
    } else if (data.orderPaymentStatus === PaymentStatusValue.Cancelled) {
      dispatch({ type: Action.UPDATE_STATUS, status: PaymentStatus.CANCELLED })
    } else {
      // Keep waiting
    }
  }, [data])
  return (
    <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
      {state.status === PaymentStatus.PENDING ? (
        <>
          <Text style={{ marginBottom: 10 }}>Fullfører betaling</Text>
          <ActivityIndicator></ActivityIndicator>
        </>
      ) : state.status === PaymentStatus.SUCCESS ? (
        <Text style={{ marginBottom: 10 }}>Takk for ditt kjøp</Text>
      ) : state.status === PaymentStatus.CANCELLED ? (
        <Text style={{ marginBottom: 10 }}>Betalingen din ble kansellert</Text>
      ) : state.status === PaymentStatus.ERROR ? (
        <Text style={{ marginBottom: 10 }}>Det har oppstått en feil</Text>
      ) : (
        <Text style={{ marginBottom: 10 }}>Something unexpected happened</Text>
      )}
      {state.status !== 'PENDING' && <Link to={{ screen: 'StudentNavigator' }}>Gå tilbake</Link>}
    </View>
  )
}

export default Payment
