import React, { useState } from 'react'
import { useEffect } from 'react'
import { useContext } from 'react'
import { LayoutAnimation, Linking, Pressable, SafeAreaView, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import Refill from '../../components/Refill'
import AuthContext from '../../contexts/auth'
import useRemainingTime from '../../hooks/useRemainingTime'
import ActionButton from '../../components/ActionButton'
import { SALES_TERMS_URL } from '../../utils/constants'

const TimeScreen = () => {
  const [open, setOpen] = useState(false)

  const { hours, minutes, isLoading } = useRemainingTime()

  const toggleOpen = () => {
    setOpen(!open)
    LayoutAnimation.configureNext(LayoutAnimation.create(150, 'easeInEaseOut', 'opacity'))
  }
  return (
    <>
      <SafeAreaView style={tw`m-3 flex-1 items-center justify-between`}>
        <View />
        {isLoading ? (
          <Text>Loading remaining time...</Text>
        ) : (
          <View style={tw`items-center`}>
            <Text style={tw`text-2xl font-bold`}>Du har</Text>
            <View style={tw`border border-grey rounded-full px-5 pt-3 my-3`}>
              <Text style={tw`text-6xl font-bold leading-none`}>
                {hours}:{minutes}
              </Text>
            </View>
            <Text style={tw`text-2xl font-bold`}>timer igjen</Text>
          </View>
        )}
        <View style={tw`items-center w-full`}>
          <Text style={tw`text-grey text-sm py-2`} onPress={() => Linking.openURL(SALES_TERMS_URL)}>
            Betalingsvilkår
          </Text>

          <ActionButton label={'Fyll på'} onPress={toggleOpen} color={'orange'} />
        </View>
      </SafeAreaView>
      {open && <Refill toggleOpen={toggleOpen} />}
    </>
  )
}

export default TimeScreen
