import React, { useContext, useState } from 'react'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import { Pressable, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import StripeAccountProgress from './progress'

const StripeNoAccount = ({ refetch }: { refetch: any }) => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const [isLoading, setIsLoading] = useState(false)

  const handleCreateStripeAccount = async () => {
    setIsLoading(true)
    await getSdk().createStripeAccount()
    await refetch()

    setIsLoading(false)
  }

  return (
    <View style={tw`mx-3 mt-6 mb-1`}>
      <Text style={tw`m-3  mb-8`}>
        For å bli godkjent som mentor og aktivere utbetalinger må vi opprette en stripe-konto for
        deg.
      </Text>
      <StripeAccountProgress status={'NOT_CONNECTED'} />
      <Pressable
        style={tw`bg-[#0679DD] text-center p-4 rounded-2xl ${isLoading ? 'opacity-50' : ''}`}
        onPress={() => !isLoading && handleCreateStripeAccount()}
      >
        <Text style={tw`text-lg text-white font-bold text-center`}>
          {isLoading ? 'Oppretter konto...' : 'Opprett stripe-konto'}
        </Text>
      </Pressable>
    </View>
  )
}

export default StripeNoAccount
