import { ExpoPushToken } from 'expo-notifications'
import { useContext, useEffect } from 'react'
import AuthContext, { AuthContextValue } from '../contexts/auth'
import { RoleValue } from '../generated/graphql'

const useMe = () => {
  const { getSdk, loginState, me } = useContext<AuthContextValue>(AuthContext)

  const { data, error, mutate } = getSdk().useMe('me', {}, {})

  const setUserRole = (role: RoleValue) => {
    if (!me) {
      // Cannot set user role without having fetched user first.
      return
    }
    return mutate(
      async () => {
        const { setUserRole } = await getSdk().setUserRole({ role })
        return { me: setUserRole }
      },
      {
        rollbackOnError: true,
        revalidate: true,
      }
    )
  }

  const updatePushToken = (token: string) => {
    return getSdk().updatePushToken({ token })
  }

  return {
    me: data?.me,
    error,
    mutate,
    setUserRole,
    updatePushToken,
  }
}

export default useMe
