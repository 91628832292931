import React from 'react'
import { SafeAreaView, ScrollView, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'

const AppAboutStudent = ({ navigation }: any) => {
  const studentTime = 45
  const studentPrice = 495
  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <ScrollView style={tw`flex-1 mx-3 my-5`}>
        <Text style={tw`text-lg font-bold`}>Min Mentor – Undervisning ved et tastetrykk</Text>
        <Text style={tw`text-base pt-4 pb-1 font-bold`}>Min Mentor gjør læring enkelt</Text>
        <Text style={tw`text-base py-2`}>
          Min Mentor AS er et norsk firma som startet med et ønske om at elever raskt og enkelt
          skulle få kontakt med de beste lærerne - og raskt få veiledning og svar på spørsmål.
        </Text>
        <Text style={tw`text-base py-2`}>
          Vi er stolte over å ha bygget opp et system som vil revolusjonere undervisingsbransjen og
          hjelpe tusenvis av elever.
        </Text>
        <Text style={tw`text-base pt-4 pb-1 font-bold`}>Hvordan løsningen fungerer</Text>
        <Text style={tw`text-base py-2`}>
          Logg inn med Vipps, fyll på kontoen din med så mye tid som du ønsker og velg en lærer. Du
          blir deretter koblet opp med den læreren du har valgt innen 15 minutter. Ha undervisning
          så lenge du vil.
        </Text>
        <Text style={tw`text-base py-2`}>
          Alle elever betaler en fast pris for en skoletime ({studentTime} minutter) på kr.{' '}
          {studentPrice},- og betaler for minimum 15 minutter – selv om undervisningsøkten er
          kortere.
        </Text>
        <Text style={tw`text-base py-2`}>
          Har du spørsmål til oss kan du sende dem på e-post til support@minmentor.no, fylle ut
          kontaktskjemaet på minmentor.no eller ringe oss på telefon 45 80 76 00.
        </Text>
      </ScrollView>
    </SafeAreaView>
  )
}

export default AppAboutStudent
