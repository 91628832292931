import React, { useContext } from 'react'
import { ActivityIndicator, FlatList, SafeAreaView, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import AuthContext, { AuthContextValue } from '../../contexts/auth'
import { calculateMentorPayout } from '../../utils/price-helpers'
import { format } from 'date-fns'

const StudentOrderHistoryScreen = ({ navigation }: any) => {
  const { getSdk } = useContext<AuthContextValue>(AuthContext)
  const { data: orderData, isValidating } = getSdk().useOrders('orders')

  return (
    <SafeAreaView style={tw`flex-1 bg-white`}>
      <View style={tw`flex-1 mx-3 my-5`}>
        {isValidating ? (
          <ActivityIndicator style={tw`my-4`} />
        ) : !orderData?.orders?.length ? (
          <Text style={tw`text-center py-2`}>Ingenting å vise enda</Text>
        ) : (
          <FlatList
            data={orderData?.orders}
            renderItem={({ item }) => (
              <View style={tw`py-4 border-b border-grey-light`}>
                <View style={tw`flex-row flex-1 justify-between`}>
                  <Text style={tw`text-xs text-grey`}>
                    {format(new Date(item?.createdAt), 'dd. MMM, HH:mm')}
                  </Text>
                </View>
                <View style={tw`flex-row flex-1 justify-between`}>
                  <Text
                    style={tw`text-md font-bold leading-none pt-2`}
                  >{`${item?.products[0].amount}x ${item?.products[0].name}`}</Text>
                  {item?.timeTransaction && (
                    <View style={tw`flex-row items-end`}>
                      <Text style={tw`text-xs text-grey mr-2`}>
                        {item?.timeTransaction?.durationInSeconds / 60} minutter
                      </Text>
                    </View>
                  )}
                </View>
                <Text style={tw`text-md leading-none`}>{item?.products[0].description}</Text>
                {item?.transactions?.map((transaction) => {
                  return (
                    <View style={tw`pt-2 mt-2`}>
                      <Text>Status: {transaction.status}</Text>
                      <Text>Pris: {transaction.priceNok / 100}</Text>
                      <Text>ID (Vipps): {transaction.externalId}</Text>
                    </View>
                  )
                })}
              </View>
            )}
          />
        )}
      </View>
    </SafeAreaView>
  )
}

export default StudentOrderHistoryScreen
