import { StripeStatusResponse } from '../../generated/graphql'
import { Linking, Text, View } from 'react-native'
import tw from '../../../lib/tailwind'
import StripeAccountProgress from './progress'
import ActionButton from '../ActionButton'
import React from 'react'

const StripePendingApproval = ({ data }: { data?: StripeStatusResponse }) => {
  return (
    <View style={tw` m-3 mb-1`}>
      <Text style={tw`m-3  mb-8`}>
        Venter på godkjenning. I mellomtiden er det mulig å redigere informasjonen du har fyllt ut.
      </Text>
      <StripeAccountProgress status={'PENDING_APPROVAL'} />

      <ActionButton
        label={'Rediger stripe-profil'}
        color={'blue'}
        onPress={() => data?.onBoardingUrl && Linking.openURL(data.onBoardingUrl)}
      />
    </View>
  )
}

export default StripePendingApproval
